import {  UserAccount, UserAccountTypes, UserInfo } from 'commons/types/user';
import { isNeedSetInfo, isOAInfo } from 'commons/utils/user';
import { infoRequiredStorage } from 'components/storage';
import React, { useRef, useState } from 'react';
import { useLoadUserAccounts } from 'services/user';
import { DialogPlugin } from 'tdesign-react/esm';
import { InfoSettingType } from './type';
import InfoSettingDialogContent from './content';
import Utils from 'utils';
import { AppProviders } from 'context';

import './index.less';

interface MutationOptions {
  /** 当前登录用户的信息 */
  user: UserInfo;
  /** 需要执行的回调 */
  callback?: () => void;
  /** 用户更新用户信息成功后的回调 */
  updateInfo?: (user: UserInfo) => void;
}

/**
 * 根据当前场景判断是否需要显示头像和昵称设置弹窗
 * @returns
 */
export const useInfoSettingDialog = () => {
  // 数据上报
  const eventCategory = '头像呢称设置提示弹窗';
  // 回调函数队列
  const callbacksRef = useRef<FunctionVoid[]>([]);
  // 更新用户信息回调
  const updateInfoRef = useRef<Function>();

  const [user, setUser] = useState<UserInfo>({});

  // 获取账号信息：用户获取当前用户的 OA 头像和昵称信息，用于判断是否 OA 头像
  const { refetch: refetchOAInfo } = useLoadUserAccounts({
    queryKey: [user.guid ?? ''],
    enabled: !!user.guid,
    onSuccess: (res) => {
      if (!user.guid) return;

      const oaInfo = res.accounts.find(item => item.kind === UserAccountTypes.oa);

      if (isOAInfo(user, oaInfo)) {
        showDialog(InfoSettingType.OA, oaInfo);
        return;
      }

      if (isNeedSetInfo(user.avatar, user.name)) {
        showDialog();
        return;
      }

      runCallbacks();
    },
  });

  const showDialog = (type = InfoSettingType.EMPTY, oaInfo?: UserAccount) => {
    Utils.horizonSend(eventCategory, '显示头像昵称设置弹窗', type);

    const dialogPlugin = DialogPlugin.confirm({
      visible: true,
      header: '头像昵称设置',
      body: (
        <AppProviders>
          <InfoSettingDialogContent
            eventCategory={eventCategory}
            type={type}
            user={user}
            oaInfo={oaInfo}
            onSaveSuccess={(info) => {
              dialogPlugin.hide();
              // 执行回调
              runCallbacks();
              // 更新全局的用户信息
              updateInfoRef.current?.({ ...user, ...info });
            }}
            onCancel={() => {
              dialogPlugin?.hide();
              // 执行回调
              runCallbacks();
            }}
          />
        </AppProviders>
      ),
      footer: false,
      width: 600,
      className: Utils.getPrefixCls('info-setting-dialog'),
      onClose: () => {
        dialogPlugin.hide();
        // 执行回调
        runCallbacks();
      },
    });
  };

  /**
   * 头像和昵称设置流程结束后，完成用户的操作
   */
  const runCallbacks = () => {
    // 执行回调
    callbacksRef.current.forEach(callback => callback?.());
    // 清空回调
    callbacksRef.current = [];
  };


  /**
   * 判断是否需要显示昵称和头像设置弹窗
   */
  const mutate = (options: MutationOptions) => {
    const { user, callback, updateInfo } = options;

    // 首先判断当前场景是否需要设置头像和昵称
    if (infoRequiredStorage.get()) {
      // 移除标识
      infoRequiredStorage.remove();

      // 记录回调
      if (typeof callback === 'function') {
        callbacksRef.current.push(callback);
      }

      if (typeof updateInfo === 'function') {
        updateInfoRef.current = updateInfo;
      }

      // 记录用户信息
      setUser(user);

      // 获取用户的 OA 信息
      refetchOAInfo();

      return;
    }

    // 不需要设置，执行回调
    callback?.();
  };

  return {
    /** 判断是否需要显示昵称和头像设置弹窗 */
    mutate,
  };
};
