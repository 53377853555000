import { UserAccount, UserInfo } from 'commons/types/user';
import UserAvatar from 'components/user-avatar';
import React, { useEffect, useRef, useState } from 'react';
import { Button, message } from 'tdesign-react/esm';
import { EditLine } from '@tencent/coral-icon';
import { CoralCropper } from '@tencent/coral-cropper';
import Utils from 'utils';
import { InfoSettingType } from './type';
import Input from 'components/input';
import { uploadImage } from 'utils/request';
import { HorizonProps } from 'commons/types/common';
import { FILE_UPLOAD_CONFIG } from 'commons/utils/config';
import { useUpdateAccountInfo } from 'services/user';
import { isOAImage, isOAName } from 'commons/utils/user';

import './content.less';

interface InfoSettingDialogContentProps extends HorizonProps {
  /** 当前提示类型 */
  type: InfoSettingType;
  /** 当前用户信息 */
  user: UserInfo;
  /** 当前用户的 OA 信息 */
  oaInfo?: UserAccount;
  /** 用户信息更新成功 */
  onSaveSuccess: (user: UserInfo) => void;
  /** 取消 */
  onCancel?: () => void;
}

/**
 * 弹窗内容的展示模式
 */
enum ContentMode {
  /** 展示模式 */
  VIEW,
  /** 编辑头像 */
  EDIT_AVATAR,
}

const configs = {
  [InfoSettingType.EMPTY]: {
    desc: () => '当前账号还没有头像/昵称，请先完成个人账号设置。',
    cancelBtnText: '稍后设置',
    confirmBtnText: '保存设置',
  },
  [InfoSettingType.OA]: {
    desc: (user: UserInfo, oaInfo?: UserAccount) => {
      const parts: string[] = [];

      if (isOAImage(user, oaInfo)) {
        parts.push('头像');
      }

      if (isOAName(user, oaInfo)) {
        parts.push('昵称');
      }

      const partsStr = parts.join('和');

      return `当前账号使用的是内网 OA ${partsStr}，即将在外网露出， 建议修改${partsStr}。`;
    },
    cancelBtnText: '暂不修改',
    confirmBtnText: '修改并使用',
  },
};

const InfoSettingDialogContent: React.FC<InfoSettingDialogContentProps> = ({
  eventCategory,
  user,
  oaInfo,
  type = InfoSettingType.EMPTY,
  onCancel,
  onSaveSuccess,
}) => {
  const prefixCls = Utils.getPrefixCls('info-setting-dialog-content');
  const cropperRef = useRef<CoralCropper>(null);
  const config = configs[type];

  const [avatar, setAvatar] = useState(user.avatar);
  const [name, setName] = useState(user.name);
  const [mode, setMode] = useState(ContentMode.VIEW);

  // 是否已经上传了合法的图片
  const [uploaded, setUploaded] = useState(false);

  useEffect(() => setAvatar(user.avatar), [user.avatar]);
  useEffect(() => setName(user.name), [user.name]);

  const { mutate: updateInfo, isLoading: isSaving } = useUpdateAccountInfo({
    onSuccess: (_, variables) => {
      message.success('保存成功');

      onSaveSuccess?.(variables);
    },
    onError: (e) => {
      message.error(e.message || '保存失败，请稍后重试');
    },
  });

  const handleBeforeUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length && e.target.files[0].size < FILE_UPLOAD_CONFIG.userAvatar.maxSize) {
      setUploaded(true);
      return true;
    }
    message.error(`图片过大，请上传${FILE_UPLOAD_CONFIG.userAvatar.maxSizeStr}以内的图片`);
    setUploaded(false);
    return false;
  };

  const handleConfirmUpload = (res: string) => {
    if (res) {
      uploadImage(Utils.dataURItoBlob(res)).then(url => setAvatar(url));

      setUploaded(false);

      setMode(ContentMode.VIEW);
    }
  };

  const handleCacnelUpload = () => setMode(ContentMode.VIEW);

  /**
   * 点击保存按钮
   */
  const handleConfirm = () => {
    updateInfo({ avatar, name });

    Utils.horizonSend(eventCategory, `C-${config.confirmBtnText}`);
  };

  /**
   * 点击取消按钮
   */
  const handleCancel = () => {
    onCancel?.();

    Utils.horizonSend(eventCategory, `C-${config.cancelBtnText}`);
  };

  const renderContent = () => {
    switch (mode) {
      case ContentMode.EDIT_AVATAR:
        return (
          <>
            <div className={`${prefixCls}__body`}>
              <p>头像支持jpg, png, jpeg格式，文件大小不超过{FILE_UPLOAD_CONFIG.userAvatar.maxSizeStr}</p>
              <div className={`${prefixCls}__edit-content`}>
                <div className={`${prefixCls}__upload-avatar`}>
                  <CoralCropper
                    ref={cropperRef}
                    defaultImg={avatar}
                    width={80}
                    height={80}
                    cropShowWidth={80}
                    cropShowHeight={80}
                    round='50%'
                    beforeUpload={handleBeforeUpload}
                    onCancel={handleCacnelUpload}
                    onConfirm={handleConfirmUpload}
                  />
                  <div className='upload-buttons'>
                    <Button
                      theme='primary'
                      variant='text'
                      onClick={() => {
                        cropperRef.current?.handleUpload();
                      }}
                    >
                      上传图片
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${prefixCls}__footer`}>
              <Button
                theme='default'
                onClick={() => {
                  cropperRef.current?.handleCancel?.();
                  Utils.horizonSend(eventCategory, 'C-头像设置-取消并返回');
                }}
              >
                取消并返回
              </Button>
              <Button
                theme='primary'
                disabled={!uploaded}
                onClick={() => {
                  cropperRef.current?.handleConfirm?.();
                  Utils.horizonSend(eventCategory, 'C-头像设置-确定');
                }}
              >
                确定
              </Button>
            </div>
          </>
        );

      default:
        return (
          <>
            <div className={`${prefixCls}__body`}>
              <p>{config.desc(user, oaInfo)}</p>
              <div className={`${prefixCls}__avatar`}>
                <UserAvatar size={80} src={avatar} />
                <Button
                  shape='circle'
                  theme='default'
                  size='small'
                  className={`${prefixCls}__btn-edit-avatar`}
                  onClick={() => setMode(ContentMode.EDIT_AVATAR)}
                >
                  <EditLine />
                </Button>
              </div>
              <div className={`${prefixCls}__name`}>
                <Input value={name} maxlength={20} onChange={e => setName(e)} />
              </div>
            </div>
            <div className={`${prefixCls}__footer`}>
              <Button theme='default' onClick={handleCancel}>
                {config.cancelBtnText}
              </Button>
              <Button
                loading={isSaving}
                theme='primary'
                disabled={!(user.avatar !== avatar || user.name !== name) || !name}
                onClick={handleConfirm}
              >
                {config.confirmBtnText}
              </Button>
            </div>
          </>
        );
    }
  };

  return <div className={prefixCls}>{renderContent()}</div>;
};

export default InfoSettingDialogContent;
