import { message } from 'tdesign-react/esm';
import { useMutation, useQuery, UseQueryOptions } from 'react-query';
import { CertificationAPI, CertificationStatus } from 'commons/types/certification';
import { MicroAppName } from 'commons/types/micro';
import request, { downloadImage } from 'utils/request';
import { OrganizationTeamListResponse } from '@tencent/microapi';
import { TdUploadFile } from 'tdesign-mobile-react';
import { ZoneType } from 'commons/types/zone';
import { useAuth } from 'context/auth-context';

/**
 * 个人认证申请
 */
export const useSaveCertificationPersonal = () => useMutation(
  (params: CertificationAPI.ParamsSaveCertificationPersonal) => {
    const api = `/certification/${MicroAppName.RESEARCH}/user`;
    const method = 'POST';
    const data = { ...params };
    return request(api, { method, data });
  },
  {
    onError: (error: API.ResultError) => message.error(error?.message as string),
  }
);

/** 团队认证申请 */
export const useSaveCertificationTeam = () => useMutation(
  (params: CertificationAPI.ParamsSaveCertificationTeam) => {
    const api = `/certification/${MicroAppName.RESEARCH}/team`;
    const method = 'POST';
    const data = { ...params };
    return request(api, { method, data });
  },
  {
    onError: (error: API.ResultError) => message.error(error?.message as string),
  }
);

/**
 * 获取单个用户认证状态返回的结果
 */
interface LoadCertificationStatusResult {
  status: CertificationStatus;
}
/**
 * 获取单个用户认证状态
 * @param params
 * @param options
 * @returns
 */
export const useLoadCertificationStatus = (
  params: CertificationAPI.ParamsLoadCertificationStatus,
  options?: UseQueryOptions<LoadCertificationStatusResult, API.ResultError, LoadCertificationStatusResult, string[]>
) => {
  const { user, setCertificationStatus } = useAuth();
  const { id, type } = params;
  const api = `/certification/${MicroAppName.RESEARCH}/${type}/${id}`;

  return useQuery(
    [api],
    () => request<LoadCertificationStatusResult>(api),
    {
      enabled: !!id,
      ...options,
      onSuccess: (result) => {
        // 如果是当前登录用户，更新全局状态
        if (user.guid === id) {
          setCertificationStatus(result.status);
        }

        options?.onSuccess?.(result);
      },
    },
  );
};

// 批量获取认证状态
export const useLoadBatchCertificationStatus = <TData = {
  usersStatus: {[key: string]: CertificationStatus};
  teamsStatus: {[key: string]: CertificationStatus};
}>(
    params: CertificationAPI.ParamsLoadBatchCertificationStatus,
    options?: UseQueryOptions<TData, API.ResultError, TData, string[]>
  ) => {
  const { ids, type } = params;
  const api = `/certification/${MicroAppName.RESEARCH}/batch/${type}`;
  const data = type === ZoneType.user ? {
    userGuids: ids,
  } : {
    teamIds: ids,
  };
  return useQuery(
    [`/certicication/${MicroAppName.RESEARCH}/${type}/batch`, ids?.join('') ?? ''],
    () => request<TData>(api, { method: 'POST', data }),
    {
      ...options,
      enabled: !!ids?.length,
    },
  );
};

/** 查询认证详情 */
export const useLoadCertificationDetail = <TData = CertificationAPI.ResultLoadDetail>(
  params: CertificationAPI.ParamsLoadCertificationStatus,
  options?: UseQueryOptions<TData, API.ResultError, TData, string[]>
) => {
  const { id, type, isEdit = false } = params;
  const api = `/certification/${MicroAppName.RESEARCH}/${type}/${id}/detail`;
  return useQuery(
    [`/certification/${MicroAppName.RESEARCH}/${type}/${id}/detail`],
    () => request<TData>(api),
    {
      ...options,
      enabled: isEdit,
    }
  );
};

/** 获取认证团队列表 */
export const useLoadCertificationTeams = <TData = OrganizationTeamListResponse>(
  { page, size },
  options?: UseQueryOptions<TData, API.ResultError, TData, string[]>
) => useQuery(
    ['/organization/teams/admin'],
    () => request<TData>('/organization/teams/admin', {
      data: {
        'pagination.page': page,
        'pagination.size': size,
      },
    }),
    {
      ...options,
    }
  );

/** 下载图片链接 */
export const useDownloadImage = (
  uuids?: (string | undefined)[],
  options?: UseQueryOptions<TdUploadFile[], API.ResultError, TdUploadFile[], string[]>
) => useQuery(
  [uuids?.join(',') ?? ''],
  () => downloadImage(uuids),
  {
    enabled: (uuids && !!uuids?.length),
    ...options,
  }
);

// 团队/个人名称申请重复验证
export const useLoadNameRepeated = <TData = CertificationAPI.ResultLoadRepeated>(
  name?: string, id?: string | number, type?: ZoneType,
  options?: UseQueryOptions<TData, API.ResultError, TData, string[]>
) => {
  const data = type === ZoneType.team ? {
    teamName: name,
    currentTeamId: id,
  } : {
    userName: name,
    userGuid: id,
  };
  const queryKeys = Object.keys(data)
    .map(key => data[key])
    .filter(v => typeof v === 'string' || typeof v === 'number');
  return useQuery(
    [`/certification/${MicroAppName.RESEARCH}/check-repeated/${type}`, ...queryKeys],
    () => request<TData>(`/certification/${MicroAppName.RESEARCH}/check-repeated/${type}`, {
      data,
    }),
    {
      ...options,
    },
  );
};
