/** 后端微服务的一些通用类型定义 */

/**
 * 后端微服务实体类型
 * - UNDEFINED           异常占位
 * - ResearchReport      报告
 * - Reporter            在线报告
 * - Comment             评论
 * - Article             文章
 * - Like                点赞
 * - Nps                 评分
 * - Collect             收藏
 * - Post                想法/讨论(帖子)
 * - INVALID_ACTION      操作的占位
   * TXCTopic            兔小巢话题
   * RegisteredNPS       NPS注册实体
   * Research            调研云
   *
 */
export enum MicroEntityType {
  UNDEFINED = 'UNDEFINED',
  ResearchReport = 'ResearchReport',
  Reporter = 'Reporter',
  Comment = 'Comment',
  Article = 'Article',
  Like = 'Like',
  Collect = 'Collect',
  Nps = 'Nps',
  INVALID_ACTION = 'INVALID_ACTION',
  RegisteredNPS = 'RegisteredNPS',
  Research = 'Research',
  TXCTopic = 'TXCTopic',
  SystemAudit = 'SystemAudit',
  SystemUnpublish = 'SystemUnpublish',
  Post = 'Post',
  ValidationCollect = 'ValidationCollect',
}

/**
 * 后端微服务实体类型名称
 */
export enum MicroEntityTypeName {
  ResearchReport = '报告',
  Comment = '评论',
  Article = '文章',
  Like = '点赞',
  Collect = '收藏',
  Nps = '评分',
  RegisteredNPS = 'NPS注册实体',
  Research = '调研云',
  TXCTopic = '兔小巢话题',
  SystemAudit = '审核',
  SystemUnpublish = '已下线',
  Post = '想法',
}

/**
 * 实体编辑器入口名称
 */
export enum MicroEntityEditorName {
  /** 本地上传报告 */
  ResearchReport = '发报告',
  /** 在线编辑报告 */
  Reporter = '写报告',
  /** 写文章 */
  Article = '写文章',
}

/**
 * 后端微服务业务名称
 * - RESEARCH           调研云
 */
export enum MicroAppName {
  RESEARCH = 'RESEARCH',
}

// 是否为帖子
export const isPostEntity = (entityType: MicroEntityType) => entityType === MicroEntityType.Post;

// 是否为评论
export const isCommentEntity = (entityType: MicroEntityType) => entityType === MicroEntityType.Comment;

// 是否为文章
export const isArticleEntity = (entityType: MicroEntityType) => entityType === MicroEntityType.Article;

// 是否为报告
export const isResearchReportEntity = (entityType: MicroEntityType) => entityType === MicroEntityType.ResearchReport;

// 是否为收藏
export const isCollectEntity = (entityType: MicroEntityType) => entityType === MicroEntityType.Collect;

// 是否为点赞
export const isLikeEntity = (entityType: MicroEntityType) => entityType === MicroEntityType.Like;

// 是否为评分
export const isNpsEntity = (entityType: MicroEntityType) => entityType === MicroEntityType.Nps;

// 是否为下线
export const isSystemUnpublish = (entityType: MicroEntityType) => entityType === MicroEntityType.SystemUnpublish;

// 是否为审核
export const isSystemAudit = (entityType: MicroEntityType) => entityType === MicroEntityType.SystemAudit;

// 是否为留资消息
export const isValidationCollect = (entityType: MicroEntityType) => entityType === MicroEntityType.ValidationCollect;

export interface MicroEntityProps {
  entityId: Raw;
  entityType: MicroEntityType;
}

/**
 * 微服务接口返回值
 */
export interface MicroApiResponse<T = unknown>  {
  data: T;
  status: number;
  statusText: string;
  headers: Record<string, string> ;
  config: any;
  request?: any;
}

export type MicroApiFn<TData = unknown> = () => Promise<MicroApiResponse<TData>>;
