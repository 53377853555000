import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import URLSearchParams from '@ungap/url-search-params';
import utils from 'utils';
import { ENV_PRODUCTION, TDE_IDS } from './env';
import { MicroEntityType } from 'commons/types/micro';
import { ServiceBindAccountType } from 'sdk-coral-enterprise/dist/types/client/ServiceLogin';
import { RoutePaths, URLSearchParamsPagination } from 'commons/route';
import { LoginOptions } from 'commons/types/user';
import { infoRequiredStorage } from 'components/storage';

type ParamKeyValuePair = [string, string];

/* eslint-disable-next-line max-len */
type URLSearchParamsInit = string | ParamKeyValuePair[] | Record<string, string | string[]> | URLSearchParams;

/**
 * 传入一个对象和需要的键值数组，返回对应的对象中的键值对
 * @param obj
 * @param keys
 * @returns
 */
const subset = <O extends Record<string, unknown>, K extends keyof O>(obj: O, keys: K[] = []): Pick<O, K> => {
  const filterEntries = Object.entries(obj).filter(([key]) => !keys.length || keys.includes(key as K));
  return Object.fromEntries(filterEntries) as Pick<O, K>;
};

export function createSearchParams(init: URLSearchParamsInit): URLSearchParams {
  if (typeof init === 'string' || Array.isArray(init) || init instanceof URLSearchParams) {
    return new URLSearchParams(init);
  }
  return new URLSearchParams(Object.keys(init).reduce((memo, key) => {
    const value = init[key];
    return memo.concat(Array.isArray(value) ? value.map(v => [key, v]) : [[key, value]]);
  }, [] as ParamKeyValuePair[]),);
}

export const useSearchParams = (defaultInit?: URLSearchParamsInit) => {
  const defaultSearchParamsRef = React.useRef(createSearchParams(defaultInit ?? ''));

  const location = useLocation();
  const searchParams = React.useMemo(() => {
    const searchParams = createSearchParams(location.search);
    for (const key of defaultSearchParamsRef.current.keys()) {
      if (!searchParams.has(key)) {
        defaultSearchParamsRef.current.getAll(key).forEach((value) => {
          searchParams.append(key, value);
        });
      }
    }
    return searchParams;
  }, [location.search]);

  const history = useHistory();
  const setSearchParams = React.useCallback(
    (nextInit: URLSearchParamsInit, navigateOptions?: { replace?: boolean }) => {
      if (navigateOptions?.replace) {
        history.replace({
          search: `?${createSearchParams(nextInit)}`,
        });
      } else {
        history.push({ search: `?${createSearchParams(nextInit)}` });
      }
    },
    [history],
  );

  return [searchParams, setSearchParams] as const;
};

export const useUrlQueryParams = <K extends string>(keys: K[] = []) => {
  const [searchParams] = useSearchParams();
  const setSearchParams = useSetUrlQueryParams();
  const [stateKeys] = React.useState(keys);
  return [
    React.useMemo(
      () => subset(Object.fromEntries(searchParams), stateKeys) as Record<K, string>,
      [searchParams, stateKeys],
    ),
    (params: Partial<Record<K, unknown>>, navigateOptions?: { replace?: boolean }) => {
      setSearchParams(params, navigateOptions);
    },
  ] as const;
};

export const useSetUrlQueryParams = () => {
  const [, setSearchParam] = useSearchParams();
  return (params: Record<string, unknown>, navigateOptions?: { replace?: boolean }) => {
    const o = utils.cleanObject({ ...params }) as URLSearchParamsInit;
    return setSearchParam(o, navigateOptions);
  };
};
/**
 * 从链接中获取分页参数
 * @param pageSize
 */
export const useURLSearchParamsPagination = (pageSize = 10) => {
  const query = Url.parseSearch<URLSearchParamsPagination>();

  const page = parseInt(query.page, 10);
  const size = parseInt(query.pageSize, 10);

  return {
    /** 当前分页 */
    page: isNaN(page) ? 1 : Math.max(page, 1),
    /** 当前分页大小 */
    pageSize: isNaN(size) ? pageSize : Math.max(size, 1),
  };
};

export default class Url {
  /**
   * 删除url的参数
   * @param params
   * @param url
   * @returns
   */
  static removeUrlParam(params: string[], url: string = window.location.href) {
    const uri = this.getRelativeUrl(url);

    params.forEach((param: string) => {
      uri.searchParams.delete(param);
    });

    return uri.href;
  }

  /**
   * 设置链url的参数
   * @param url 需要设置参数的链接
   * @param params 需要设置的参数
   * @param origin 是否需要完整的链接
   * @returns
   */
  static addUrlParam(
    params: Record<string, string | number>,
    url: string = window.location.href,
    origin = false,
  ): string {
    const uri = this.getRelativeUrl(url);

    // 设置参数上的值
    if (!!params) {
      Object.keys(params).forEach((key) => {
        if (params[key]) {
          uri.searchParams.set(key, `${params[key]}`);
        }
      });
    }

    if (!origin && uri.href.split(location.origin).length > 1) {
      return uri.href.split(location.origin)[1];
    }

    return uri.href;
  }

  /**
   * 获取链接中的参数。值为数字字符串时类型转换为数字
   * @param url
   * @param excludes 不需要的参数key
   * @returns
   */
  static parseSearch<T, K = keyof T>(url: string = location.search, excludes: K[] = []): T {
    const { searchParams } = this.getRelativeUrl(url);
    const result: T = {} as unknown as T;
    for (const [key, value] of searchParams.entries()) {
      if (!excludes.includes(key as K)) {
        result[key] = typeof value === 'string' && value.trim() !== '' && !isNaN(Number(value)) ? Number(value) : value;
      }
    }
    return result;
  }

  /**
   * 根据问卷id获取问卷链接
   */
  static getSurveyUrl(id: string): string {
    return `https://wj.qq.com/edit.html?sid=${id}`;
  }

  /**
   * 获取绝对路径的url参数
   * @param url 链接
   */
  static getRelativeUrl = (url: string): URL => {
    try {
      return new URL(url);
    } catch (e) {
      return new URL(location.origin + url);
    }
  };

  /**
   * 测试环境在链接中补充 _tde_id 参数
   * @param url 链接地址
   * @param _tde_id 对应的环境id
   * @returns
   */
  static addTdeId(url: string, _tde_id: number): string {
    return ENV_PRODUCTION ? url : Url.addUrlParam({ _tde_id }, url);
  }
}

/**
 * 登录相关的地址
 */
export class LoginUrl {
  /**
   * 账号绑定页面地址
   * @param bindAccountType 绑定的账号类型
   * @returns
   */
  static getLoginBindUrl(bindAccountType: ServiceBindAccountType) {
    const loginUrl = this.getLoginUrl({ infoRequired: false });

    return Url.addUrlParam({ s_bind_account_type: bindAccountType }, loginUrl);
  }

  /**
   * 登录地址
   */
  static getLoginUrl(options: LoginOptions = {}): string {
    const { infoRequired = true } = options;

    if (infoRequired) {
      infoRequiredStorage.set(true);
    }

    const redirectUrl = encodeURIComponent(options.redirectUrl || location.href);

    return `/login?s_url=${redirectUrl}`;
  }
}

/**
 * 页面地址
 */
export class PageUrl {
  /**
   * 获取加入团队页面地址
   * @returns
   */
  static getInvitePageUrl() {
    const pageUrl = `${window.origin}${RoutePaths.teamInvite}`;

    return Url.addTdeId(pageUrl, TDE_IDS.research_01);
  }

  /**
   * 获取团队管理页面地址
   * @param teamId 团队 ID
   * @returns
   */
  static getTeamManageUrl(teamId: number | string = 0) {
    return RoutePaths.teamManage.replace(':teamId', `${teamId}`);
  }

  /**
   * 获取个人工作台链接地址
   */
  static getWorkSpacePersonalUrl(entityType?: MicroEntityType) {
    if (entityType) {
      return `${RoutePaths.workspacePersonal}/?tab=${
        entityType === MicroEntityType.ResearchReport ? 'report' : 'article'
      }`;
    }
    return RoutePaths.workspacePersonal;
  }

  /**
   * 获取团队工作台链接地址
   * @param teamId 团队 ID
   * @returns
   */
  static getWorkSpaceTeamUrl(teamId: number, entityType?: MicroEntityType) {
    if (entityType) {
      return `${RoutePaths.workspaceTeam}/${teamId}/${entityType}`;
    }
    return `${RoutePaths.workspaceTeam}/${teamId}`;
  }

  /**
   * 获取认证链接地址
   * @param id 认证 ID
   * @return
   */
  static getCertificationUrl(id?: Raw) {
    const searchParams: Record<string, Raw> = {};

    if (id) {
      searchParams.teamId = id;
    }

    return Url.addUrlParam(searchParams, RoutePaths.certification);
  }
}

/**
 * 通用举报问卷地址
 * @param url 问卷预填举报内容链接
 */
export const getWJReportUrl = (url?: string) => `https://wj.qq.com/s2/10196922/656a?Q1=${encodeURIComponent(url || window.location.href)}`;
