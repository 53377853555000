import React from 'react';
import { CoralUser } from '@tencent/coral-user';
import { CoralUserProps } from '@tencent/coral-user/dist/types';
import Utils from 'utils';

import './index.less';

/**
 * - XS: 24px， 报告卡片：作者头像 ｜ 消息中心引用评论作者头像；
 * - S: 28px， 头部导航消息盒子：用户头像；
 * - M: 32px，头部导航头像 ｜ 消息中心浮层评论作者头像；
 * - L: 40px，空间推荐：用户头像；
 * - XL: 48px， 报告评论：用户头像 | 文章列表：作者头像 ｜ 推荐列表：作者头像；
 * - XXL: 72px，个人空间：用户头像；
 * */
export const AvatarSize = {
  XS: 24,
  S: 28,
  M: 32,
  L: 40,
  XL: 48,
  XXL: 72,
};

const UserAvatar = (props: CoralUserProps) => {
  const prefixCls = Utils.getPrefixCls('user-avatar');

  return (
    <CoralUser
      className={prefixCls}
      {...props}
    />
  );
};

export default UserAvatar;
