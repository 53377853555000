import { GlobalStorage } from './storage';
import { RecommendSectionChoiceStorageData } from './types';

/**
 * 每周精选弹窗相关配置
 */
export const recommendSectionChoiceStorage = new GlobalStorage<RecommendSectionChoiceStorageData>('recommended_section_choice');

/**
 * 登录成功后是否显示账号设置指引弹窗
 */
export const infoRequiredStorage = new GlobalStorage<boolean>('info_required');

/**
 * 消息中心新手指引弹框相关配置
 */
export const messageCenterGuideStorage = new GlobalStorage<boolean>('message_center');

/** 文章发布器插入目录新手指引弹窗 */
export const addArticleDirectoryGuideStorage = new GlobalStorage<boolean>('add-article-directory');

/**
 * 记录历史页面地址，用于新开页面点击回退按钮时回到上一个页面
 */
export const historyBackUrlStorage = new GlobalStorage<string>('history_back_url');
