export enum ALL_NODE_ENV {
  production = 'production',
  test = 'test',
  development = 'development'
}

/** 是否线上环境 */
export const ENV_PRODUCTION = process.env.REACT_APP_NODE_ENV === ALL_NODE_ENV.production;

/** 是否测试环境 */
export const ENV_TEST = process.env.REACT_APP_NODE_ENV === ALL_NODE_ENV.test;

/** 是否本地开发环境 */
export const ENV_DEVELOPMENT = process.env.REACT_APP_NODE_ENV === ALL_NODE_ENV.development;

/** 测试环境地址相同时，通过 tde_id 区分不同环境 */
export const TDE_IDS = {
  /** 问卷 - kamlyli2 */
  wj_01: 7020,
  /** 问卷 - boris */
  wj_02: 9737,
  /** 调研云 - 周更测试环境 */
  research_01: 6891,
  /** 调研云 - staging04 */
  research_04: 9741,
};

/** 第三方平台的地址 */
export const THIRD_URLS = {
  wj: ENV_PRODUCTION ? 'https://wj.qq.com' : 'https://dev.wj.qq.com',
};

/**
 * 企业服务 sdk-coral-enterprise 包含的服务页面地址
 */
export const SDK_URLS = {
  /** 账号设置指引 */
  account_setting_guide: '/embed/account_setting_guide.html',
  /** 登录页面 */
  org_login: '/embed/login.html',
  /** 登录页面（移动端） */
  org_login_mobile: '/embed/page/mobile/login.html',
  /** 创建团队 */
  org_create: '/embed/org_create.html',
  /** 解散团队 */
  org_remove: '/embed/org_remove.html',
  /** 移交团队 */
  org_transfer: '/embed/org_transfer.html',
  /** 添加人员：通过链接邀请 */
  org_invite_link: '/embed/org_invite_link.html',
  /** 加入团队 */
  org_invite_page: '/embed/org_invite_page.html',
  /** 成员管理 */
  org_members: '/embed/org_members.html',
};


/**
 * 平台静态文件链接
 */
export const STATIC_URLS = {
  /** 平台入驻合作协议 */
  personalCooperation: 'https://docs.qq.com/doc/DVGRUTFpYeEJhSnJq?&u=b6af1ad5e1d4438cb84311a06d8a5f9b',
  teamCooperation: 'https://docs.qq.com/doc/DVEZ5U3hBZVNEcXp4?u=b6af1ad5e1d4438cb84311a06d8a5f9b',
  /** 腾讯调研云账号申请及超级管理员授权书 */
  adminAuthorize: 'https://docs.qq.com/doc/DVG1ERE9NUXNHR216?u=b6af1ad5e1d4438cb84311a06d8a5f9b',
  /** 平台服务协议 */
  agreement: 'https://docs.qq.com/doc/DVHNoRnNudHhaQWh3?&u=b6af1ad5e1d4438cb84311a06d8a5f9b',
  /** 平台隐私协议 */
  privacypolicy: 'https://docs.qq.com/doc/DVEdLdE94TnNKcW1Y?&u=b6af1ad5e1d4438cb84311a06d8a5f9b',
  /** 腾讯服务协议 */
  contract: 'https://www.qq.com/contract.shtml',
  /** 腾讯调研云用户内容上传发布协议 */
  reportAgreement: 'https://docs.qq.com/doc/DVGtJSlZPR29GZVFh?&u=b6af1ad5e1d4438cb84311a06d8a5f9b',
};

/**
 * 支持的全部平台
 */
export enum ALL_PLATFORM {
  mobile = 'mobile',
  others = 'others',
}

/**
 * 获取当前用户的平台
 * @return {string}
 */
export function getPlatform(): string {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
    return ALL_PLATFORM.mobile;
  }
  return ALL_PLATFORM.others;
}

/**
 * 是否移动端
 */
export const IS_MOBILE = getPlatform() === ALL_PLATFORM.mobile;
// export const IS_MOBILE = true;

/**
 * 防水墙图形验证码 CaptchaAppId
 */
export const CAPTCHA_APP_IDS = {
  /**
   * 报告 用于下载、预览报告时进行验证
   */
  report: '199959384',
  /**
   * 个人认证/团队认证
   */
  certificate: '2006634644',
};
