/* eslint-disable @typescript-eslint/no-magic-numbers */
/**
 * 上传文件大小限制（bytes）
 */
export const FILE_UPLOAD_CONFIG = {
  /** 用户头像 */
  userAvatar: {
    maxSize: 5 * 1024 * 1024,
    maxSizeStr: '5M',
    maxSizeMsg: '',
    fileTypeMsg: '支持 JPG、PNG、JPEG 格式，文件大小不超过 5M',
  },
  /**
   * 发帖子时上传的图片
   */
  postImage: {
    maxSize: 5 * 1024 * 1024,
    maxSizeStr: '5M',
    maxSizeMsg: '',
    fileTypeMsg: '支持 JPG、PNG、JPEG 格式，文件大小不超过 5M',
    // 最多上传 6 张
    maxCount: 6,
    maxCountStr: '图片不要超过6张哦',
  },
};

/**
 * 发布帖子时字数上限
 */
export const POST_CONTENT_MAX_LENGTH = 400;

/**
 * 定时器延时毫秒
 */
export const TIMEOUT_DELAY = 300;

/**
 * 毫秒换算
 */
export const MILLI_SECOND = 1000;
