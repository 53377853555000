import React, { ReactNode, useContext } from 'react';

const HEADER_HEIGHT = 64;

interface LayoutContextValue {
  // 头部高度：默认是90
  headerHeight: number;
}

const LayoutContext = React.createContext<LayoutContextValue | undefined>(undefined);
LayoutContext.displayName = 'LayoutContext';

export const LayoutProvider = ({ children }: { children: ReactNode }): React.ReactElement => (
    <LayoutContext.Provider
      value={{
        headerHeight: HEADER_HEIGHT,
      }}
    >
      {children}
    </LayoutContext.Provider>
);

export const useLayout = (): LayoutContextValue => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error('useLayout 必须在LayoutProvider中使用');
  }
  return context;
};
