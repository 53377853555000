import React, { ReactNode, useState, useContext, useRef } from 'react';
import { LoginOptions  } from 'commons/types/user';
import { infoRequiredStorage } from 'components/storage';
import { useHistory } from 'react-router-dom';
import { LoginUrl } from 'utils/url';

interface AuthContextValue {
  /** 登录成功后需要执行的回调 */
  callbackRef: React.MutableRefObject<FunctionVoid | undefined>;
  /** 显示全局登录弹窗 */
  show: boolean;
  /** 关闭登录弹窗 */
  onClose: () => void;
  /** 显示登录弹窗 */
  onShow: (options: LoginOptions) => void;
  /** 登录成功 */
  onSuccess: () => void;
  /** 跳转到登录页面 */
  redirectToLoginPage: (options: LoginOptions) => void;
}

const LoginContext = React.createContext<AuthContextValue | undefined>(undefined);
LoginContext.displayName = 'LoginContext';

export const LoginProvider = ({ children }: { children: ReactNode }): React.ReactElement => {
  // 记录回调
  const callbackRef = useRef<FunctionVoid>();

  const history = useHistory();

  // 是否显示全局登录弹窗
  const [show, setShow] = useState(false);

  /**
   * 关闭登录弹窗
   */
  const onClose = () => setShow(false);

  /**
   * 打开登录弹窗
   * @param options
   */
  const onShow = (options: LoginOptions) => {
    const { infoRequired = true, callback } = options;

    // 记录当前场景是否需要设置头像和昵称
    if (infoRequired) {
      infoRequiredStorage.set(true);
    }

    // 打开登录弹窗
    setShow(true);

    // 记录回调
    if (typeof callback === 'function') {
      callbackRef.current = callback;
    }
  };

  /**
   * 登录成功
   */
  const onSuccess = () => {
    setShow(false);
    // 执行回调
    // callbacksRef.current.forEach(callback => callback?.());
    // 回调统一在头像和昵称设置弹窗中执行
  };

  /**
   * 跳转到登录页面
   * @param options
   */
  const redirectToLoginPage = (options: LoginOptions = {}) => {
    const loginUrl = LoginUrl.getLoginUrl(options);

    // 使用 push，用户可以通过浏览器回退按钮回到前一个页面
    history.push(loginUrl);
  };

  return (
    <LoginContext.Provider value={{
      callbackRef,
      /** 是否显示登录弹窗 */
      show,
      /** 关闭全局登录弹窗 */
      onClose,
      /** 显示全局登录弹窗 */
      onShow,
      /** 登录成功回调 */
      onSuccess,
      /** 跳转到登录页面 */
      redirectToLoginPage,
    }}>{children}</LoginContext.Provider>
  );
};

export const useLogin = (): AuthContextValue => {
  const context = useContext(LoginContext);
  if (!context) {
    throw new Error('useLogin 必须在LoginProvier中使用');
  }
  return context;
};
