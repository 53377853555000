import { useCallback, useEffect, useRef, useState } from 'react';
import { useLoadBatchCertificationStatus } from 'services/certification';
import { IArticle } from 'commons/types/article';
import { CertificationStatus } from 'commons/types/certification';
import { ReportModel, ResearchReportModel } from 'commons/types/report';
import { ZoneType } from 'commons/types/zone';

interface UseCertificationStatusIds {
  userIds?: string[];
  teamIds?: string[];
}

/**
 * 从数据列表中获取用户id和团队id
 * @param list
 * @returns
 */
export const getCertificationStatusIds = (list: (ResearchReportModel.DetailContent| IArticle | ReportModel.Item)[]) => {
  const userIds: string[] = [];
  const teamIds: string[] = [];
  list?.forEach((item) => {
    const teamId = item?.teams?.[0]?.teamId;
    const userId = item?.author?.guid;
    if (userId) {
      userIds.push(userId);
    }

    if (teamId) {
      teamIds.push(String(teamId));
    }
  });

  return { teamIds, userIds };
};

/**
 * 团队/个人认证状态
 * @param list
 * @returns
 */
const useCertificationStatus = ({ userIds = [], teamIds = [] }: UseCertificationStatusIds) => {
  // 个人认证状态数据
  const userStatusRef = useRef<Record<string, CertificationStatus>>({});
  // 团队认证状态
  const teamStatusRef = useRef<Record<string, CertificationStatus>>({});

  const [uIds, setUIds] = useState(userIds);
  const [tIds, setTIds] = useState(teamIds);

  useEffect(() => setUIds(userIds), [userIds.join(',')]);
  useEffect(() => setTIds(teamIds), [teamIds.join(',')]);

  // 获取个人认证状态
  useLoadBatchCertificationStatus({ ids: uIds, type: ZoneType.user }, {
    onSuccess: (data) => {
      userStatusRef.current = {
        ...userStatusRef.current,
        ...data.usersStatus,
      };
    },
  });
  // 获取团队认证状态
  const {
    isFetching: isFetchTeamCertificationStatus,
  } = useLoadBatchCertificationStatus({ ids: tIds, type: ZoneType.team }, {
    onSuccess: (data) => {
      teamStatusRef.current = {
        ...teamStatusRef.current,
        ...data.teamsStatus,
      };
    },
  });

  const getTeamCertificationStatus = (teamId: string | number = '') => teamStatusRef.current?.[teamId] ?? CertificationStatus.uncertified;

  const getUserCertificationStatus = (userId = '') => userStatusRef.current?.[userId] ?? CertificationStatus.uncertified;

  const getCertificationStatus = (id = '', type = ZoneType.user) => (
    type === ZoneType.team ? getTeamCertificationStatus(id) : getUserCertificationStatus(id)
  );

  const fetchUserStatus = useCallback((userIds: string[]) => setUIds(userIds), []);

  const fetchTeamStatus = useCallback((teamIds: string[]) => setTIds(teamIds), []);


  return {
    getCertificationStatus,
    /** 当前用户的认证状态 */
    getUserCertificationStatus,
    /** 当前团队的认证状态 */
    getTeamCertificationStatus,
    /** 获取团队认证状态数据 */
    fetchTeamStatus,
    /** 获取用户认证状态数据 */
    fetchUserStatus,
    isFetchTeamCertificationStatus,
  };
};

export default useCertificationStatus;
