import React from 'react';
import styled from 'styled-components';
import { Loading } from 'tdesign-react/esm';

const PageLoading: React.FC<React.ComponentProps<typeof Loading>> = ({ loading = true, className, children }) => (
  <Loading loading={loading} text="" className={className}>
    {children}
  </Loading>
);

export const FullPageLoading = styled(PageLoading)`
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
