import { ZoneType } from './zone';

// 认证主体类型
export enum EnterpriseType {
  /** 企业认证 */
  enterprise = 'enterprise',
  /** 政府/事业单位认证 */
  government = 'government',
  /** 媒体认证 */
  media = 'media',
  /** 学校/教育机构认证 */
  education = 'education',
  /** 其他机构认证 */
  other = 'other',
};
// 证件
export enum IDTypes {
  /** 居民身份证 */
  id_card = 'id_card',
  /** 护照 */
  passport = 'passport',
  /** 港澳通行证 */
  hk_macao_pass = 'hk_macao_pass',
}

export enum IDTypeNames {
  id_card = '居民身份证',
  passport = '护照',
  hk_macao_pass = '港澳通行证',
}

export enum CertificationStatus {
  /** 已认证 */
  certified = 'certified',
  /** 审核中 */
  certifying = 'certifying',
  /** 未认证 */
  uncertified = 'uncertified',
  /** 认证失败 */
  failed = 'failed',
}

export enum CertificationStatusName {
  certified = '已认证',
  certifying = '审核中',
  uncertified = '未认证',
  failed = '认证失败',
}
// 未认证
export const isUnCertified = (status?: CertificationStatus) => status === CertificationStatus.uncertified;
// 认证中
export const isCertifying = (status?: CertificationStatus) => status === CertificationStatus.certifying;
// 已认证
export const isCertified = (status?: CertificationStatus) => status === CertificationStatus.certified;
// 认证失败
export const isCertifiFiled = (status?: CertificationStatus) => status === CertificationStatus.failed;

/**
 * 认证的类型
 */
export enum CertificationType {
  user = 'user',
  team = 'team'
}

/**
 * 认证包含的keys
 */
export enum CertificationKeys {
  /** 真实姓名 */
  userRealName = 'userRealName',
  /** 证件类型 */
  idType = 'idType',
  /** 证件号 */
  idNumber = 'idNumber',
  /** 手持证件照 */
  idFileUuid = 'idFileUuid',
  /** 资格证书 */
  certificationFileUuids = 'certificationFileUuids',
  /** 电子邮箱 */
  email = 'email',
  /** 个人QQ */
  qq = 'qq',
  /** 个人微信 */
  wechat = 'wechat',
  /** 确认函照片 */
  agreementFileUuid = 'agreementFileUuid',
  /** 手机号 */
  phone = 'phone',
  /** 验证码 */
  verifyCode = 'verifyCode',
  /** 认证团队id */
  teamId = 'teamId',
  /** 认证主体类型 */
  enterpriseType = 'enterpriseType',
  /** 企业名称 */
  enterpriseName = 'enterpriseName',
  /** 营业执照注册号 */
  businessLicense = 'businessLicense',
  /** 营业执照 */
  businessLicenseFileUuid = 'businessLicenseFileUuid',
  /** 其他资质证明材料 */
  otherCertificationFileUuids = 'otherCertificationFileUuids',
  /** 授权书照片上传 */
  authorizationFileUuid = 'authorizationFileUuid',
  /** 事业单位法人证书 */
  legalPersonFileUuid = 'legalPersonFileUuid',
}

export declare namespace CertificationAPI {
  // 个人认证申请参数
  type ParamsSaveCertificationPersonal = {
    [CertificationKeys.userRealName]: string;
    [CertificationKeys.idType]: IDTypes;
    [CertificationKeys.idNumber]: string;
    [CertificationKeys.idFileUuid]: string;
    [CertificationKeys.agreementFileUuid]: string;
    [CertificationKeys.email]: string;
    [CertificationKeys.qq]: string;
    [CertificationKeys.wechat]: string;
    [CertificationKeys.certificationFileUuids]: string[];
    [CertificationKeys.phone]: string;
    [CertificationKeys.verifyCode]: string;
  };
  // 团队认证申请参数
  type ParamsSaveCertificationTeam = ParamsSaveCertificationPersonal & {
    [CertificationKeys.teamId]: number;
    [CertificationKeys.enterpriseType]: EnterpriseType;
    [CertificationKeys.enterpriseName]: string;
    [CertificationKeys.businessLicense]: string;
    [CertificationKeys.businessLicenseFileUuid]: string;
    [CertificationKeys.otherCertificationFileUuids]: string[];
    [CertificationKeys.authorizationFileUuid]: string;
    [CertificationKeys.legalPersonFileUuid]: string;
  };

  // 查询认证状态参数
  type ParamsLoadCertificationStatus = {
    id?: string | number;
    type: ZoneType;
    isEdit?: boolean;
  };

  // 批量查询认证状态参数
  type ParamsLoadBatchCertificationStatus = {
    ids?: string[] | number[];
    type: ZoneType;
  };

  type ResultLoadDetail = {
    code: number;
    message: string;
    detail: ParamsSaveCertificationTeam;
  };
  // 图片资源返回结果
  type ResultLoadImageDetail = {
    code: number;
    data: string;
    filename: string;
    md5: string;
    mimetype: string;
  };

  // 名称查重返回结果
  type ResultLoadRepeated = {
    code: number;
    isRepeated: boolean;
    teamId: string;
    userGuid: string;
  };

  interface CertifyStatusResult {
    code?: number;
    /** 认证状态*/
    status?: CertificationStatus;
  }

  /** 批量查询认证状态-返回结果 */
  interface BatchCertifyStatusResult {
    code?: number;
    /** 个人认证状态 */
    usersStatus?: Record<string, CertificationStatus>
    /** 团队认证状态 */
    teamsStatus?: Record<string | number, CertificationStatus>;
  }
}
