import { UserAccount, UserInfo } from 'commons/types/user';

/**
 * 当前登录用户是否需要绑定手机号
 * @param user
 * @returns
 */
export const isNeedBindPhone = (userType = '') => userType !== 'PHONE'; // 只有当 userType 的值为 PHONE 时才算绑定过手机号

/**
 * 当前用户是否需要设置头像或昵称
 * - 头像为空时
 * - 昵称为空时
 * - 昵称为 “微信用户” 时（兼容未获取到微信昵称的场景）
 * @param avatar 头像
 * @param name 昵称
 * @returns
 */
export const isNeedSetInfo = (avatar = '', name = '') => !avatar || !name || name === '微信用户';

/**
 * 判断是否 OA 头像
 * @param user
 * @param oaInfo
 * @returns
 */
export const isOAImage = (user: UserInfo, oaInfo?: UserAccount) => oaInfo?.avatar === user.avatar || user.avatar?.includes('woa.com');

/**
 * 判断是否 OA 昵称
 * @param user
 * @param oaInfo
 * @returns
 */
export const isOAName = (user: UserInfo, oaInfo?: UserAccount) => oaInfo?.name === user.name;

/**
 * 判断当前用户信息是否使用了 OA 头像信息
 * @param user 当前登录用户的信息
 * @param oaInfo 当前登录用户对应的 OA 信息（包括头像、昵称等）该信息只有用户绑定了 OA 账户才有
 */
export const isOAInfo = (user: UserInfo, oaInfo?: UserAccount) => isOAImage(user, oaInfo) || isOAName(user, oaInfo);
