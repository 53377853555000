import React from 'react';
import { Route, RouteProps } from 'react-router';
import Utils from 'utils';
import { useAuth } from 'context/auth-context';
import { useLogin } from 'context/login-context';

/**
 * 添加登录态校验逻辑的路由。用于需要登录才能访问的页面
 * @returns
 */
function PrivateRoute({ component, ...rest }: RouteProps) {
  const { logined, logining } = useAuth();
  const { redirectToLoginPage } = useLogin();
  const Component = component as React.FC;

  return (
    <Route
      {...rest}
      render={() => {
        if (logining) return null;
        if (logined) return <Component />;
        Utils.horizonSend('登录', '自动登录');
        redirectToLoginPage({ infoRequired: false });
      }}
    />
  );
}

export default PrivateRoute;
