export enum ResponseCode {
  /**
   * 成功的状态码
   */
  Success = 0,
  /**
   * 市调大赛关注团队审核不通过
   */
  ZoneFollowRefused = 150003,
  /**
   * 报告查看票据：报告设置了留资查看但还没有填写资料
   */
  ReportTicketNeedUserInfo = 100415,
  /**
   * 无权限
   */
  Forbidden = 403,
}

/** 默认的分页 id */
export const DEFAULT_PAGE_ID = 0;
/** 默认的分页大小 */
export const DEFAULT_PAGE_SIZE = 10;
/** 默认的分页第一页 */
export const DEFAULT_PAGE_FIRST = 1;

export type ResponseError = {
  code: ResponseCode;
  message?: string;
};

export type ResponseSuccess = {
  code: ResponseCode.Success;
};

export interface RequestConfig extends RequestInit {
  data?: any;
  rtx?: string;
  mock?: boolean; // 是否mock数据
  apiUrl?: string; // 自定义apiUrl
  loginPath?: string; // 接口403跳转地址
  needLogin?: boolean; // 接口是否需要登录后才能访问，默认为true，返回的状态码为403时重定向到登录页面
  needCookie?: boolean; // 是否需要cookie
}
