import React from 'react';
import classNames from 'classnames';
import Utils from 'utils';
import icon from './assets/forbidden.png';

import './index.less';

interface ErrorFallbackProps {
  error: Error;
}

// 全局的错误状态。用作 ErrorBoundary 的 FallbackComponent
// TODO: 缺失错误状态图标，待后面品牌提供图后替换
const ErrorFallback = ({ error }: ErrorFallbackProps): React.ReactElement => {
  const prefixCls = Utils.getPrefixCls('error-status');
  return (
    <div className={classNames(prefixCls, `${prefixCls}-fallback`)}>
      <div className={`${prefixCls}__icon`}>
        <img src={icon} alt={error.message} />
      </div>
      <p className={`${prefixCls}__desc`}>{error.message}</p>
      <div className={`${prefixCls}__extra`}>
        <a className={`${prefixCls}__link`} href='/'>回到首页</a>
      </div>
    </div>
  );
};

export default ErrorFallback;
