import React, { lazy } from 'react';
import {
  DiscoverRoute,
  DiscoverRouteTab,
  EntityRoute,
  MessageRoute,
  MessageRouteTab,
  SearchRoute,
  SearchRouteTab,
  ZoneRoute,
  ZoneRouteTab,
} from 'commons/route';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { useSearchParams } from 'utils/url';
import Url from 'commons/utils/url';
import { ZoneType } from 'commons/types/zone';
const SearchResult = lazy(() => import('pages/search-result'));

/**
 * 先保留部分变更前的路由地址,避免访问存量地址无法打开
 */
export const HistoryPaths = {
  discover: '/discover',
  /** 发现 - 报告 */
  discoverReport: '/discover/report',
  /** 发现 - 文章 */
  discoverArticle: '/discover/article',
  /** 发现 - 工具 */
  discoverTool: '/discover/tool',

  /** 文章详情 */
  article: '/article/:articleId',
  /** 报告详情页 */
  researchReport: '/report/:reportId',

  /** 空间：个人空间 */
  zoneUser: '/zone/user/:guid',
  /** 空间：个人空间-报告 */
  zoneUserReport: '/zone/user/:guid/report',
  /** 空间：个人空间-文章 */
  zoneUserArticle: '/zone/user/:guid/article',
  /** 空间：个人空间-关注者 */
  zoneUserFollowers: '/zone/user/:guid/followers',

  /** 空间：团队空间 */
  zoneTeam: '/zone/team/:teamId',
  /** 空间：团队空间 - 报告 */
  zoneTeamReport: '/zone/team/:teamId/report',
  /** 空间：团队空间 - 文章 */
  zoneTeamArticle: '/zone/team/:teamId/article',
  /** 空间：团队人空间-关注者 */
  zoneTeamFollowers: '/zone/team/:teamId/followers',

  /** 搜索结果 */
  search: '/search',
  /** 搜索结果-全部 */
  searchAll: `/search/${SearchRouteTab.all}`,
  /** 搜索结果-报告 */
  searchReport: `/search/${SearchRouteTab.report}`,
  /** 搜索结果-文章 */
  searchArticle: `/search/${SearchRouteTab.article}`,
  /** 搜索结果-空间 */
  searchZone: `/search/${SearchRouteTab.zone}`,

  /** 消息中心 */
  notification: '/notification',
  /** 消息中心-回复我的 */
  notificationComment: `/notification/${MessageRouteTab.comment}`,
  /** 消息中心-赞和收藏 */
  notificationLike: `/notification/${MessageRouteTab.like}`,
  /** 消息中心-报告评分 */
  notificationNps: `/notification/${MessageRouteTab.nps}`,
  /** 消息中心-系统消息 */
  notificationSystem: `/notification/${MessageRouteTab.system}`,
};

/**
 * 空间
 * @returns
 */
export const HistoryZone = () => {
  const { guid, teamId } = useParams<{ guid: string; teamId: string }>();
  const zoneId = teamId || guid;
  const zoneType = teamId ? ZoneType.team : ZoneType.user;
  const zonePath = teamId ? HistoryPaths.zoneTeam : HistoryPaths.zoneUser;

  return (
    <Switch>
      {Object.keys(ZoneRouteTab).map(tab => (
        <Redirect key={tab} from={`${zonePath}/${tab}`} to={ZoneRoute.path(zoneId, zoneType, tab as ZoneRouteTab)} />
      ))}
      <Redirect to={ZoneRoute.path(zoneId, zoneType)} />
    </Switch>
  );
};

/**
 * 搜索
 * @returns
 */
export const HistorySearch = () => {
  const { keyword } = Url.parseSearch<{ keyword: string }>();

  return (
    <Switch>
      {Object.keys(SearchRouteTab).map(tab => (
        <Redirect
          key={tab}
          from={`${HistoryPaths.search}/${tab}`}
          to={SearchRoute.path(tab as SearchRouteTab, keyword)}
        />
      ))}
      <Route path={SearchRoute.root} component={SearchResult} />
    </Switch>
  );
};

/**
 * 报告详情页
 * @returns
 */
export const HistoryReport = () => {
  const { reportId } = useParams<{ reportId: string }>();
  const [searchParams] = useSearchParams();

  return <Redirect to={EntityRoute.pathReport({ id: reportId, ...searchParams })} />;
};

/**
 * 文章详情页
 * @returns
 */
export const HistoryArticle = () => {
  const { articleId } = useParams<{ articleId: string }>();
  const [searchParams] = useSearchParams();

  return <Redirect to={EntityRoute.pathArticle({ id: articleId, ...searchParams })} />;
};

/**
 * 消息中心
 * @returns
 */
export const HistoryMessage = () => (
  <Switch>
    <Redirect from={`${HistoryPaths.notification}/like`} to={MessageRoute.path(MessageRouteTab.like)} />
    {Object.keys(MessageRouteTab).map(tab => (
      <Redirect key={tab} from={`${HistoryPaths.notification}/${tab}`} to={MessageRoute.path(tab as MessageRouteTab)} />
    ))}
    <Redirect to={MessageRoute.path(MessageRouteTab.comment)} />
  </Switch>
);

/**
 * 发现
 * @returns
 */
export const HistoryDiscover = () => (
  <Switch>
    {Object.keys(DiscoverRouteTab).map(tab => (
      <Redirect key={tab} from={`/discover/${tab}`} to={DiscoverRoute.path(tab as DiscoverRouteTab)} />
    ))}
    <Redirect to={DiscoverRoute.path(DiscoverRouteTab.recommend)} />
  </Switch>
);
