export default class Url {
  /**
   * 删除url的参数
   * @param params
   * @param url
   * @returns
   */
  static removeUrlParam(params: string[], url: string = window.location.href) {
    const uri = this.getRelativeUrl(url);

    params.forEach((param: string) => {
      uri.searchParams.delete(param);
    });

    return uri.href;
  }

  /**
   * 设置链url的参数
   * @param url 需要设置参数的链接
   * @param params 需要设置的参数
   * @param origin 是否需要完整的链接
   * @returns
   */
  static addUrlParam(
    params: Record<string, string | number>,
    url: string = window.location.href,
    origin = false,
  ): string {
    const uri = this.getRelativeUrl(url);

    // 设置参数上的值
    if (!!params) {
      Object.keys(params).forEach((key) => {
        if (params[key]) {
          uri.searchParams.set(key, `${params[key]}`);
        }
      });
    }

    if (!origin && uri.href.split(window.location.origin).length > 1) {
      return uri.href.split(window.location.origin)[1];
    }

    return uri.href;
  }

  /**
   * 获取链接中的参数。值为数字字符串时类型转换为数字
   * @param url
   * @param excludes 不需要的参数key
   * @returns
   */
  static parseSearch<T, K = keyof T>(url: string = window.location.search, excludes: K[] = []): T {
    const { searchParams } = this.getRelativeUrl(url);
    const result: T = {} as unknown as T;
    for (const [key, value] of searchParams.entries()) {
      if (!excludes.includes(key as K)) {
        result[key] =
          typeof value === 'string' && value.trim() !== '' && !Number.isNaN(Number(value)) ? Number(value) : value;
      }
    }
    return result;
  }

  /**
   * 获取绝对路径的url参数
   * @param url 链接
   */
  static getRelativeUrl = (url: string): URL => {
    try {
      return new URL(url);
    } catch (e) {
      return new URL(window.location.origin + url);
    }
  };
}

/**
 * 自定义为路径添加参数函数
 * @param path 路径
 * @param params 参数
 */
export const appendPathParams = (path: string, params: Object = {}) => {
  const qs: string[] = [];

  Object.keys(params).forEach((key) => {
    if (params[key]) {
      qs.push(`${key}=${encodeURIComponent(params[key] || '')}`);
    }
  });

  if (!qs.length) {
    return path;
  }

  return `${path}${path.indexOf('?') === -1 ? '?' : '&'}${qs.join('&')}`;
};
