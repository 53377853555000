import React, { Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { FullPageLoading } from 'components/lib';
import { useLoadUserInfo, useLoadWechatIsSubscribed } from 'services/user';
import ErrorFallback from 'components/error-status/fallback';
import { ServiceLogin } from 'sdk-coral-enterprise';
import { MicroAppName } from 'commons/types/micro';
import { SDK_URLS } from 'utils/env';
import { useAuth } from 'context/auth-context';
import { useLoadNoticeSwitch } from 'services/message-center';
import { AppProviders } from 'context';
import useCertificationStatus from 'hooks/useCertificationStatus';
import { useLogin } from 'context/login-context';

export interface RootAppConfig {
  /** 要渲染的元素 */
  app?: React.ReactNode;
}

export interface RootAppProps {
  config: RootAppConfig;
}

const App: React.FC<RootAppProps> = (props) => {
  const { setIsLoadingFollowOfficialAccounts } = useAuth();
  // 是否开启了消息推送
  const { mutate: loadNoticeSwitch } = useLoadNoticeSwitch();
  // 用户认证状态
  const { fetchUserStatus } = useCertificationStatus({});
  // 是否关注公众号
  const { mutate: loadWechatIsSubscribed, isLoading: isLoadingFollowOfficialAccounts } = useLoadWechatIsSubscribed();
  // 登录弹窗控制
  const { show: showLogin, onClose: onCloseLogin, onSuccess: onLoginSuccess } = useLogin();

  // 获取用户信息
  const { refetch: refetchUserInfo } = useLoadUserInfo({
    onSuccess: (res) => {
      // 获取用户是否开启消息推送
      loadNoticeSwitch();
      // 获取用户是否关注公众号
      loadWechatIsSubscribed();
      // 获取用户认证状态
      fetchUserStatus([res.user.guid!]);
    },
  });

  useEffect(() => {
    setIsLoadingFollowOfficialAccounts(isLoadingFollowOfficialAccounts);
  }, [isLoadingFollowOfficialAccounts]);

  return (
    <div className='root-app'>
      <Suspense fallback={<FullPageLoading />}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {props.config.app}

          <ServiceLogin
            visible={showLogin}
            autoLogin={false}
            closeBtn={true}
            service={SDK_URLS.org_login}
            appid={MicroAppName.RESEARCH}
            onSuccess={() => {
              refetchUserInfo();
              onLoginSuccess();
            }}
            onClose={onCloseLogin}
          />
        </ErrorBoundary>
      </Suspense>

      {/* 用于将网站链接发到企微、微信等平台时自动解析网页内容 */}
      <img src='https://image-75140.gzc.vod.tencent-cloud.com/e275dc25-35cb-3e0c-adf3-e82cc9ae3e04' alt='' style={{ display: 'none' }} />
    </div>
  );
};

const RootApp: React.FC<RootAppProps> = props => (
  <AppProviders>
    <App {...props} />
  </AppProviders>
);

export default RootApp;
