import { ENV_PRODUCTION } from 'utils/env';

/**
 * 安全操作 window.localStorage 和 sessionStorage
 * 隐身模式和部分 Android 客户端会关闭 localStorage
 * 导致 Cannot read property 'getItem' of null 等异常
 */
export class GlobalStorage<T> {
  /**
   * 存储的 key
   */
  key: string;
  /**
   * 存储类型，支持 localStorage 和 sessionStorage
   */
  storage: Storage;

  constructor(key: string, isSession?: boolean) {
    this.key = `research-user-data-${key}`;
    this.storage = isSession ? window.sessionStorage : window.localStorage;
  }

  set(input: T) {
    try {
      this.storage.setItem(this.key, JSON.stringify(input));
      return true;
    } catch (error) {
      this.errorHandler(error);
      return false;
    }
  }

  get(): T | null {
    try {
      /** 浏览器支持 JSON.parse(null) 行为，ts 定义只支持 string 不准确  */
      /** @ts-ignore */
      return JSON.parse(this.storage.getItem(this.key));
    } catch (error) {
      this.errorHandler(error);
      return null;
    }
  }

  remove() {
    try {
      this.storage.removeItem(this.key);
      return true;
    } catch (error) {
      this.errorHandler(error);
      return false;
    }
  }

  /**
   * 本地开发和测试环境，输出异常信息用于调试
   */
  protected errorHandler(error: unknown) {
    if (!ENV_PRODUCTION) {
      return;
    }

    console.error(error);
  }
}
