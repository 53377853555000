import { MicroAppName, MicroEntityType } from 'commons/types/micro';
import { InteractionCounter, OutputCounter } from 'commons/types/common';
import { IArticle } from 'commons/types/article';
import { CertificationStatus } from 'commons/types/certification';
import { JointSearch } from 'commons/types/joint-publish';
import { ReportSourceType, ResearchReportModel } from 'commons/types/report';
import { TeamModel } from 'commons/types/team';
import { UserInfo } from 'commons/types/user';
import { ResponseCode } from 'commons/types/api';
import { PostModel } from 'commons/types/post';
import { StatsActionStatus } from 'components/stats-meta/types';

/** 空间类型 */
export enum ZoneType {
  /** 个人空间 */
  user = 'user',
  /** 团队空间 */
  team = 'team',
}

/**
 * 空间类型（后端的是大写的形式）
 */
export enum ZoneKind {
  User = 'USER',
  Team = 'TEAM',
}

export enum ZoneTypeName {
  /** 个人空间 */
  user = '个人',
  /** 团队空间 */
  team = '团队',
}

/** 个人空间信息分类 */
export enum UserZoneInfoCategory {
  /** 简介 */
  intro = 'intro',
  /** 成就 */
  achievement = 'achievement',
  /** 联系方式 */
  contact = 'contact',
  /** 报告分布 */
  researchreport_distribute = 'researchreport_distribute',
  /** 文章分布 */
  article_distribute = 'article_distribute',
  /** 团队 */
  team = 'team',
}

export enum UserZoneInfoKey {
  /** 背景图 */
  banner = 'banner',
  /** 个人简介 */
  resume = 'resume',
  /** 总阅读量 */
  view_count = 'view_count',
  /** 内容阅读量 */
  preview_count = 'preview_count',
  /** 获得赞 */
  like_count = 'like_count',
  /** 获得评论 */
  comment_count = 'comment_count',
  /** 内容被收藏 */
  collect_count = 'collect_count',
}

/** 联系方式 */
export enum UserZoneContact {
  email = 'email',
  qq = 'qq',
  phone = 'phone',
  wechat = 'wechat',
}

/** 联系方式名称 */
export enum UserZoneContactName {
  email = '邮箱',
  qq = 'QQ',
  phone = '手机',
  wechat = '微信',
}

export declare namespace UserZoneModel {
  /** 个人空间信息 */
  type InfoItem = {
    id: Raw;
    index: number; // 序号
    key: string;
    title: string; // 名称
    value: string; // 值
    category: UserZoneInfoCategory; // 分类
    categoryTitle: string; // 分类名
    children: InfoItem[];
  };

  /** 个人空间报告分布-标签分布的数据 */
  type InfoResearchReportDistributeTag = {
    ID: number;
    Name: string;
    TagType: string;
    Count: number;
    Id: number;
  };

  /** 文章&报告总数 */
  type Counter = {
    report: number;
    article: number;
    post: number;
    reportPublic: number;
    articlePublic: number;
  };
}

export declare namespace UserZoneAPI {
  /** 更新个人空间信息 */
  interface UpdateZoneUser {
    items: { id: Raw; value: string }[];
  }

  interface ResultLoadZoneUser {
    code: number;
    data: UserZoneModel.InfoItem[];
    user: UserInfo;
    counter: UserZoneModel.Counter;
    followerCount: number;
  }

  interface ResultLoadZoneTeam {
    code: number;
    data: UserZoneModel.InfoItem[];
    team: TeamModel.TeamInfo;
    counter: UserZoneModel.Counter;
    followerCount: number;
  }
}

/** ================== 空间推荐 ================== */
export declare namespace ZoneRecommendedModel {
  /** 推荐空间展示的 报告/文章 */
  interface ZoneEntity {
    id: Raw;
    /** 标题 */
    title: string;
    /** 类型：报告/文章 */
    type: MicroEntityType;
    /** 时间戳 */
    createdTime?: number;
  }

  /**
   * 推荐空间
   *  - 推荐页：空间推荐
   *  - 空间页：小编推荐
   */
  interface ZoneInfo {
    id: Raw;
    /** 空间头像 */
    avatar: string;
    /** 当前空间已发布的报告数和文章数 */
    counter?: Record<string, number>;
    /** 空间的类型：个人/团队 */
    customType: ZoneType;
    /** 推荐的报告/文章列表 */
    entities: ZoneEntity[];
    /** 空间的id：可用于拼接空间对应的链接 */
    spaceEntityId: string;
    /** 空间的名称 */
    name: string;
    /** 认证状态 */
    certificationStatus: CertificationStatus;
    /** 移动端插入位置 */
    postionMobile?: number;
    /** PC端插入位置 */
    postionPc?: number;
  }
}

export declare namespace ZoneRecommendedAPI {
  /**
   * 空间推荐接口返回的数据
   *  - 发现页：空间推荐
   *  - 空间页：小编推荐
   */
  interface ResultLoadZoneRecommended {
    spaceInfoList: ZoneRecommendedModel.ZoneInfo[];
  }
}

/** ================== 空间列表 ================== */

/** 排序方式 */
export enum ZoneListOrders {
  /** 综合排序 */
  Overall = 'Overall',
  /** 作品最多 */
  Works = 'Works',
  /** 人气最高 */
  Popularity = 'Popularity',
  /** 最近更新 */
  Latest = 'Latest',
  /** 人气上升 */
  PopularityIncrease = 'PopularityIncrease',
  /** 阅读上升 */
  ReadIncrease = 'ReadIncrease',
}

export enum ZoneListOrderNames {
  /** 综合排序 */
  Overall = '综合排序',
  /** 作品最多 */
  Works = '作品最多',
  /** 人气最高 */
  Popularity = '人气最高',
  /** 最近更新 */
  Latest = '最近更新',
  /** 人气上升 */
  PopularityIncrease = '人气上升',
  /** 阅读上升 */
  ReadIncrease = '阅读上升',
}

export declare namespace ZoneListModel {
  /**
   * 空间列表展示的 报告/文章
   */
  interface ZoneEntity {
    /** 类型：报告/文章 */
    entityType: MicroEntityType;
    /** 报告详情数据 */
    report: ResearchReportModel.DetailContent;
    /** 文章详情数据 */
    article: IArticle;
    /** 帖子详情数据 */
    post: PostModel.DetailContent;
    /** 报告/文章的阅读量等数据 */
    counter: InteractionCounter;
    /** 互动状态 */
    actionStatus: StatsActionStatus;
  }

  /**
   * 浏览记录列表项
   */

  interface BorwsingHistoryItem {
    entity: ZoneEntity;
    /** 当天首次访问时间 */
    firstAt: string;
    /** 当天最近一次访问时间 */
    lastAt: string;
    /** 访问日期 */
    viewDate: string;
  }

  /**
   * 空间列表展示的 空间信息
   */
  interface ZoneInfo {
    /** 空间id */
    id: string;
    /** 空间头像 */
    avatar: string;
    /** 空间名称 */
    name: string;
    /** 空间描述 */
    description: string;
    /** @deprecated 空间类型：个人/团队，type 在后端不是枚举类型，考虑到空间列表或者其他地方仍在使用未删除，请使用 kind */
    type: ZoneType;
    /** 空间类型：个人/团队 --- 注意大小写使用，业务中使用转小写，接口参数转大写 */
    kind: ZoneType;
    /** 认证状态 */
    certificationStatus: CertificationStatus;
  }

  /**
   * 空间列表项
   */
  interface ZoneItem {
    /** 已发布的报告数和文章数 */
    counter?: OutputCounter;
    /** 选取的报告/文章列表 */
    entities: ZoneEntity[];
    /** 空间信息 */
    info: ZoneInfo;
  }

  interface ZoneRecommendEntity {
    id: string;
    title: string;
    type: MicroEntityType;
    createdTime?: number;
  }

  /** 推荐单条数据 */
  interface ZoneRecommendedItem {
    id?: string;
    /** 头像 */
    avatar: string;
    /** 类型（个人/团队） */
    customType: ZoneType;
    /** 名称 */
    name: string;
    /** 空间id */
    spaceEntityId: string;
    /** 认证状态 */
    certificationStatus?: CertificationStatus;
    /** 报告、文章数量 */
    counter?: OutputCounter;
    /** 报告、文章列表 */
    entities: ZoneRecommendEntity[];
    /** 移动端插入位置 */
    postionMobile?: number;
    /** PC端插入位置 */
    postionPc?: number;
    /** 是否发布过报告/文章 */
    hasEntities?: boolean;
    /** 是否有空开的报告/文章 */
    hasPublicEntities?: boolean;
  }
}

export declare namespace ZoneListAPI {
  /**
   * 空间列表接口参数
   */
  interface ParamsLoadZoneList extends Partial<API.Pagination> {
    /** 页码 */
    page: number;
    /** 每页的数量 */
    size: number;
    /** 排序方式 */
    orderBy: ZoneListOrders;
    /** 搜索关键字 */
    keyword?: string;
  }
  /**
   * 空间列表接口返回结果
   */
  interface ResultLoadZoneList {
    pagination: API.Pagination;
    spaces: ZoneListModel.ZoneItem[];
  }

  /** 请求筛选器结果 */
  type ResultSearchZoneList = {
    pagination: API.Pagination;
    list: ZoneListModel.ZoneItem[];
  };
}

/** =========================== 每周精选接口返回的数据 =========================== */
export declare namespace ZoneChoiceModel {
  /** 空间信息 */
  interface Space {
    /** 头像 */
    avatar: string;
    /** id 用于拼接进去空间的地址 */
    id: string;
    /** 昵称 */
    name: string;
    /** 类型：个人/团队 */
    type: ZoneType;
  }
  interface Item {
    hashId: string;
    /** 封面图地址 */
    cover: string;
    /** 标题 */
    title: string;
    /** 推荐理由 */
    reason: string;
    /** 空间相关的信息 */
    space: Space;
    /** 实体类型 */
    type: MicroEntityType;
    /** 实体来源 */
    sourceType: ReportSourceType;
    /** 联合发布者 - 团队 */
    teamPublishers: JointSearch.ITeams[];
    /** 联合发布者 - 个人 */
    userPublishers: JointSearch.IUsers[];
    /** 团队 */
    teams: TeamModel.TeamInfo[];
  }

  interface ResultLoadZoneChoice {
    items: Item[];
    startDate: string;
  }
}

/** =========================== 空间关注 =========================== */

/**
 * 关注状态
 */
export enum ZoneFollowStatus {
  /**
   * 已关注
   */
  followed = 'followed',
  /**
   * 未关注
   */
  unfollowed = 'unfollowed',
  /**
   * 审核中
   */
  reviewing = 'reviewing',
}

export enum ZoneFollowStatusText {
  /**
   * 已关注
   */
  followed = '已关注',
  /**
   * 未关注
   */
  unfollowed = '关注',
  /**
   * 审核中
   */
  reviewing = '申请审核中',
}

/** 获取指定空间信息 */
export declare namespace BatchZoneModel {
  interface SpaceOwner {
    id: Raw;
    type: ZoneType;
  }
  /** 接口入参 */
  interface Params {
    app: MicroAppName;
    spaceOwners: SpaceOwner[];
  }

  /** 批量请求的空间信息 */
  interface ZoneInfo {
    /** 发布的内容 */
    counter: OutputCounter;
    /** 空间基础信息 */
    info: ZoneListModel.ZoneInfo;
    /** 是否已经关注 */
    isFollowed: boolean;
    /** 关注是否需要验证 */
    needAudit: boolean;
  }

  /** 空间信息 */
  interface ZoneInfoIdMap {
    [key: string]: ZoneInfo;
  }

  /** 接口返回 */
  interface Result {
    code: number;
    spaceInfoMap: {
      [key: string]: {
        spaceInfoIdMap: ZoneInfoIdMap;
      };
    };
  }

  /**
   * 批量请求的空间信息在前端格式化处理后的结果
   */
  interface FormattedResult {
    code: ResponseCode;
    /**
     * 全部空间信息
     * 健值是对应的空间 id + 类型 eg：wj_0000001-user、260-team
     */
    spaceInfos: Record<string, ZoneInfo>;
    /**
     * 用户的空间信息
     * 键值是用户的 guid
     */
    user: Record<string, ZoneInfo>;
    /**
     * 团队的空间信息
     * 键值是团队的id
     */
    team: Record<string, ZoneInfo>;
  }
}

export declare namespace ZoneFollowModel {
  interface ZoneID {
    id?: string;
    kind?: ZoneKind;
  }
  interface ZoneFollowReq {
    id?: ZoneID;
    note?: string;
  }

  export interface ZoneFollowResp {
    code?: number;
  }

  export interface ZoneUnFollowResp {
    code?: number;
  }

  interface IsZoneFollowed {
    isFollowed?: boolean;
    needAudit?: boolean;
    spaces?: ZoneID;
  }

  export interface IsZoneFollowedResp {
    code?: number;
    spaces?: IsZoneFollowed[];
  }
}
