import React, { ReactNode, useState } from 'react';
import { Input as TInput, InputProps } from 'tdesign-react/esm';
import Utils from 'utils';

import './index.less';

/**
 * 基于 td 的 Input 组件封装，如果有设置 maxLength，则显示对应的长度限制
 */
const Input: React.FC<InputProps> = ({ defaultValue, maxlength, onChange, ...restProps }) => {
  const prefixCls = Utils.getPrefixCls('input');
  const [value, setValue] = useState(defaultValue);
  const currValue = typeof restProps.value === 'undefined' ? value : restProps.value;

  let suffix: ReactNode = '';
  /**
   * 有设置 maxlength，则显示对应的长度限制
   */
  if (typeof maxlength === 'number') {
    suffix = (
      <span className={`${prefixCls}__suffix`}>
        {`${(String(currValue ?? '')).length}/${maxlength}`}
      </span>
    );
  }


  return (
    <TInput
      className={prefixCls}
      defaultValue={defaultValue}
      suffix={suffix}
      maxlength={maxlength}
      onChange={(value, context) => {
        onChange?.(value, context);
        setValue(value);
      }}
      {...restProps}
    />
  );
};

export default Input;
