import { MessageRoute, MessageRouteTab } from 'commons/route';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { MicroEntityType } from 'commons/types/micro';

interface MessageCenterCount {
  commentCount: number;
  collectLikeCount: number;
  npsCount: number;
  systemMessageCount: number;
  count: number;
}

export interface MessageCenterTab {
  name: string;
  count: number;
  text: string;
  url: string;
  entityTypes: MicroEntityType[];
}

interface MessageCenterContextValue {
  value: MessageCenterCount;
  messageCenterTabs: MessageCenterTab[];
  setValue: (messageCenterCount: MessageCenterCount) => void;
}

const MessageCenterCountValue: MessageCenterCount = {
  commentCount: 0,
  collectLikeCount: 0,
  npsCount: 0,
  systemMessageCount: 0,
  count: 0,
};

const MessageCenterContext = createContext<MessageCenterContextValue | undefined>(undefined);

MessageCenterContext.displayName = 'MessageCenterContext';

/**
 * 不同的 tab 对应的实体类型
 */
export const MessageTabEntityTypes = {
  [MessageRouteTab.comment]: [MicroEntityType.Comment],
  [MessageRouteTab.like]: [MicroEntityType.Collect, MicroEntityType.Like],
  [MessageRouteTab.nps]: [MicroEntityType.Nps],
  [MessageRouteTab.system]: [
    MicroEntityType.SystemAudit,
    MicroEntityType.SystemUnpublish,
    MicroEntityType.ValidationCollect,
  ],
};

export const MessageCenterProvider = ({ children }: { children: ReactNode }): React.ReactElement => {
  const [value, setValue] = useState(MessageCenterCountValue);

  const paths = MessageRoute.paths();

  const messageCenterTabs: MessageCenterTab[] = [
    {
      name: '回复我的',
      count: value.commentCount,
      text: 'comment',
      url: paths[MessageRouteTab.comment],
      entityTypes: MessageTabEntityTypes[MessageRouteTab.comment],
    },
    {
      name: '赞和收藏',
      count: value.collectLikeCount,
      text: 'collectLike',
      url: paths[MessageRouteTab.like],
      entityTypes: MessageTabEntityTypes[MessageRouteTab.like],
    },
    {
      name: '报告评分',
      count: value.npsCount,
      text: 'nps',
      url: paths[MessageRouteTab.nps],
      entityTypes: MessageTabEntityTypes[MessageRouteTab.nps],
    },
    {
      name: '系统消息',
      count: value.systemMessageCount,
      text: 'systemMessage',
      url: paths[MessageRouteTab.system],
      entityTypes: MessageTabEntityTypes[MessageRouteTab.system],
    },
  ];

  return (
    <MessageCenterContext.Provider
      value={{
        value,
        messageCenterTabs,
        setValue,
      }}
    >
      {children}
    </MessageCenterContext.Provider>
  );
};

export const useMessageCenter = (): MessageCenterContextValue => {
  const context = useContext(MessageCenterContext);
  if (!context) {
    throw new Error('useMessageCenter 必须在MessageCenterProvider中使用');
  }
  return context;
};
