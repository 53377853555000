import Aegis from '@tencent/aegis-web-sdk';
import anthozoanConfig from '@tencent/anthozoan-config';

/**
 * TAM上报：新建一个 Aegis 实例
 * 仅在生产环境上报
 */
export const setupAegis = () => {
  if (process.env.REACT_APP_NODE_ENV === 'production') {
    const aegis = new Aegis({
      id: 'mzXucSEzdECbjJGcJh', // 项目ID
      reportApiSpeed: true, // 接口测速
      reportAssetSpeed: true, // 静态资源测速
      beforeRequest: (data) => {
        /**
         * 拦截 ResizeObserver loop 相关的错误警告：当前错误告警由第三方库引起，且不影响功能使用，屏蔽避免大量收集到此错误
         * 参考：
         * 1. beforeRequest 官方文档：https://aegis.woa.com/sdk/web.html#beforerequest
         * 2. TAM 常见问题：https://km.woa.com/group/11800/articles/show/386426?kmref=search&from_page=1&no=1
         * 3. KM 关于此报错的解决方案：https://km.woa.com/group/51926/articles/show/526802
         */
        if ((data.logType === 'log' && data.logs.msg.indexOf('ResizeObserver loop limit exceeded') > -1)
          || (data.logType === 'log' && data.logs.msg.indexOf('ResizeObserver loop completed with undelivered notifications') > -1)) {
          return false;
        }
      },
    });

    window.aegis = aegis;
  }
};

/**
 * horizon 前端埋点初始化
 */
export const setupHorizon = () => {
  // 保存对应script标签的id值，登录成功后通过该id值获取当前script标签设置data-user-id属性值
  window.horizonTrackerScriptId = 'horizon-tracker';

  const _horizon: any = {};
  const t = document.createElement('script');
  t.type = 'text/javascript';
  t.async = true;
  t.id = window.horizonTrackerScriptId;
  t.setAttribute('data-account', `research.tencent.com.${process.env.REACT_APP_NODE_ENV}`);
  t.setAttribute('data-user-id', '');
  t.src = 'https://horizon-assets.qq.com/analytics.js';
  document.body.appendChild(t);
  window._horizon = _horizon;
};

/**
 * 统一登录组件配置。rdb 模块还在使用，暂时还不能删除
 */
export const setupAnthozoanConfig = () => {
  // eslint-disable-next-line new-cap
  window.anthozoanConfig = new anthozoanConfig({
    name: '腾讯调研云',
    appid: process.env.REACT_APP_ANTHOZOAN_CONFIG_APPID,
    namespace: process.env.REACT_APP_ANTHOZOAN_CONFIG_NAMESPACE,
  });
};
