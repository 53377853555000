import { GlobalCookie } from './cookie';

/**
 * horizon sid
 */
export const COOKIE_HORIZON_SID = new GlobalCookie('_horizon_sid');

/**
 * horizon uid
 */
export const COOKIE_HORIZON_UID = new GlobalCookie('_horizon_uid');
