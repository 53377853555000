import React from 'react';
import ReactDOM from 'react-dom';

import RootApp, { RootAppConfig } from './root-app';
import { setupAnthozoanConfig, setupAegis, setupHorizon } from './setup';

import '@tencent/coral-style';
import './styles/root-app.less';
import './styles/main.less';

export class RootPage {
  config: RootAppConfig;

  constructor(config: RootAppConfig) {
    this.config = config;
    // 启动登录服务
    setupAnthozoanConfig();
    // 数据上报
    setupHorizon();
    // TAM 上报
    setupAegis();

    this.render();
  }

  render() {
    const div = document.createElement('div');
    div.id = 'root';
    document.body.appendChild(div);

    ReactDOM.render(<RootApp config={this.config} />, div);
  }
}
