import { UserApi } from '@tencent/microapi';
import { useAuth } from 'context/auth-context';
import { MutationOptions, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import { MicroAppName } from 'commons/types/micro';
import { AccountAPI, UserAccount, UserInfo, LoadWechatIsSubscribedResult, LoginStatus } from 'commons/types/user';
import request, { requestMicroApi } from 'utils/request';
import { useLoadSwitchNoticeRemind } from 'services/message-center';
import { isNeedBindPhone } from 'commons/utils/user';

const userApi = new UserApi(undefined, window.origin);

/** useLoadUserInfo result */
type UseLoadUserInfoResult = { user: UserInfo};
/** useLoadUserInfo options */
type UseLoadUserInfoOptions = UseQueryOptions<UseLoadUserInfoResult, API.ResultError, UseLoadUserInfoResult, string[]>;
/**
 * 获取当前登录的用户信息
 */
export const useLoadUserInfo = (options?: UseLoadUserInfoOptions) => {
  const { onLogin } = useAuth();
  return useQuery(
    ['/user/v2/info'],
    () => request<UseLoadUserInfoResult>(`/user/v2/${MicroAppName.RESEARCH}/info`, { needLogin: false  }), // 在未登录时，返回的code为403，不自动跳到登录页，由用户点击登录或前端路由跳转
    {
      ...options,
      onSuccess: (res) => {
        const { user } = res;
        onLogin({
          status: isNeedBindPhone(res.user.userType) ? LoginStatus.needBindPhone : LoginStatus.success,
          user: res.user,
        });
        options?.onSuccess?.(res);

        if (user.guid) {
          // 设置TAM上报的uin
          window.aegis?.setConfig?.({
            uin: user.guid,
          });

          // 设置horizon的data-user-id
          document.getElementById(window.horizonTrackerScriptId)?.setAttribute('data-user-id', user.guid);

          // 记录用户 guid, 用于设置 header 头
          window.__USER_GUID__ = res.user.guid || '';
        }
      },
      onError: (e: API.ResultError) => {
        onLogin({ status: LoginStatus.error, user: {} });
        options?.onError?.(e);
      },
    }
  );
};

/**
 * 获取当前账号绑定的第三方账号
 * @param options
 * @returns
 */
export const useLoadUserAccounts = <TData = { accounts: UserAccount[] }>(
  options?: UseQueryOptions<TData, API.ResultError, TData, string[]>
) => useQuery(
    ['/user/accounts', ...(options?.queryKey ?? [])],
    () => request<TData>('/user/accounts'),
    options
  )
  ;

/**
 * 解绑第三方账号
 * @returns
 */
export const useUnbindAccount = <TData = API.ResultData>(
  options: MutationOptions<TData, API.ResultError, string>
) => useMutation(
    (openId: string) => request<TData>(`/user/accounts?openid=${openId}`, { method: 'DELETE' }),
    options,
  );

/**
 * 修改用户头像和昵称
 */
export const useUpdateAccountInfo = <TData = API.ResultData>(
  options: MutationOptions<TData, API.ResultError, AccountAPI.UpdateAccountInfo>
) => useMutation(
    (params: AccountAPI.UpdateAccountInfo) => request<TData>('/user/info', { method: 'PUT', data: params }),
    options
  );

/**
 * 退出登录
 */
export const useLogout = (options?: MutationOptions) => {
  const { onLogout } = useAuth();

  return useMutation(() => requestMicroApi(() => userApi.userLogoutV2(MicroAppName.RESEARCH, location.host)), {
    onSuccess: onLogout,
    ...options,
  });
};

/** 获取公众号关注二维码, 带guid */
export const useLoadUserQRCodeWechat = <TData = { expireSeconds: number; url: string }>(
  options?: UseMutationOptions,
) => useMutation([`/user/${MicroAppName.RESEARCH}/wechat/qrcode`], () => request<TData>(`/user/${MicroAppName.RESEARCH}/wechat/qrcode`), {
    ...options,
  });


/** 获取用户是否关注公众号 */
export const useLoadWechatIsSubscribed = (options?: UseMutationOptions<
LoadWechatIsSubscribedResult,
API.ResultError
>) => {
  const { setIsFollowOfficialAccounts } = useAuth();
  // 设置消息推送开关
  const { mutate: switchNoticeRemind } = useLoadSwitchNoticeRemind();

  return useMutation(
    () => request<LoadWechatIsSubscribedResult>(`/user/${MicroAppName.RESEARCH}/subscribe-status`),
    {
      ...options,
      onSuccess: (res, ...args) => {
        // 记录用户是否关注公众号状态
        setIsFollowOfficialAccounts(res.wechatIsSubscribed);

        // 如果用户未关注公众号，直接设置消息推送开启，实现关注公众号后自动开启消息推送功能
        if (!res.wechatIsSubscribed) {
          switchNoticeRemind(true);
        }

        options?.onSuccess?.(res, ...args);
      },
    }
  );
};

