import React, { ReactNode } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AuthProvider } from 'context/auth-context';
import { LayoutProvider } from 'context/layout-context';
import { MessageCenterProvider } from './message-center-context';
import { LoginProvider } from 'context/login-context';
import zhCN from 'antd/lib/locale/zh_CN';
import { ConfigProvider } from 'antd';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import defaultTheme from '../style/variables.module.less';

export const AppProviders = ({ children }: { children: ReactNode }): React.ReactElement => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });

  return (
    <ConfigProvider locale={zhCN}>
      <ThemeProvider theme={defaultTheme as DefaultTheme}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <MessageCenterProvider>
              <LoginProvider>
                <AuthProvider>
                  <LayoutProvider>{children}</LayoutProvider>
                </AuthProvider>
              </LoginProvider>
            </MessageCenterProvider>
          </Router>
          {/* react-query 调试工具面板 */}
          <ReactQueryDevtools />
        </QueryClientProvider>
      </ThemeProvider>
    </ConfigProvider>
  );
};
