import dayjs, { Dayjs } from 'dayjs';
import { camelCase } from 'lodash-es';
import { AuthorInfo } from 'commons/types/user';

export default class Utils {
  /** 是否为空 */
  static isEmpty(value: unknown): boolean {
    return value === undefined || value === null || value === '';
  }

  /** 获取完整路径 */
  static getURL(pathname = '') {
    return `${window.location.origin}${pathname ?? ''}`;
  }

  /**
   * 清除对象里值为空的属性
   * @param object
   * @returns
   */
  static cleanObject<T>(object?: T): T {
    if (!object) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return {} as T;
    }
    const result = { ...object };
    Object.keys(result).forEach((key) => {
      const k = key as keyof T;
      if (Utils.isEmpty(result[k])) {
        delete result[k];
      }
    });
    return result;
  }

  /**
   * 去除字符串中的html标签
   * @param str
   * @returns
   */
  static clearHTMLTag(str: string | undefined): string {
    if (!str || str.length === 0) return '';
    return str
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&amp;/g, '&')
      .replace(/&(lt|gt|amp);/g, '')
      .replace(/&nbsp;/g, ' ')
      .replace(/&apos;/g, '\'')
      .replace(/&#39;/g, '\'')
      .replace(/&#34;/g, '"')
      .replace(/&#96;/g, '`')
      .replace(/&quot;/g, '"')
      .replace(/&mdash;/g, '—')
      .replace(/&ldquo;/g, '“')
      .replace(/&rdquo;/g, '”')
      .replace(/<[^<>]+?>/g, '');
  }

  static parseData<T>(source: string | undefined, type: 'object' | 'array' = 'object'): T {
    const emptyData = {
      object: {},
      array: [],
    };
    if (!source) return emptyData[type] as T;
    try {
      const result = JSON.parse(source);
      return result as T;
    } catch (error) {
      return emptyData[type] as T;
    }
  }

  static formatDateCN(date?: Dayjs | Date, units: dayjs.UnitType[] = ['y', 'M', 'date']): string {
    const unitTexts: Partial<Record<dayjs.UnitType, string>> = {
      y: '年',
      M: '月',
      date: '日',
    };
    const m: Dayjs = dayjs(date);
    const data: string[] = [];
    units.forEach((unit) => {
      const v = m.get(unit) + (unit === 'M' ? 1 : 0);
      data.push(`${v}${unitTexts[unit] ?? ''}`);
    });
    return data.join('');
  }

  /**
   * 将对象数组转为以指定键的值为键的对象
   */
  static arrayToObject<T, K extends keyof T>(source: T[], key: K): Record<string, T> {
    const result: Record<string, T> = {};
    if (Array.isArray(source)) {
      source.forEach((item) => {
        const k = item[key] || '';
        result[k as string] = item;
      });
    }
    return result;
  }

  static getClientHeight(offset = 0): number {
    return (document.documentElement.clientHeight || document.body.clientHeight) - offset;
  }

  /* 获取对象数组中某一属性值的集合
   * @param key
   * @param source 源数据
   * @returns
   */
  static getAttrs<T, K>(key: keyof T, source?: T[]): K[] {
    return (Array.isArray(source) ? source : []).map(s => s[key] as unknown as K);
  }

  static horizonSend(...args: any[]) {
    // eslint-disable-next-line no-underscore-dangle
    if (window._horizon && typeof window._horizon.send === 'function') {
      // eslint-disable-next-line no-underscore-dangle
      window._horizon.send(...args);
    }
  }

  /**
   * 时间戳转成统一时间格式
   */
  static formatCommonDate(
    timestamp: string | number | undefined,
    type?: 'YYYY-MM' | 'YYYY-MM-DD' | 'YYYY-MM-DD HH:mm'
  ) {
    const formatType = Utils.isEmpty(type) ? 'YYYY-MM-DD HH:mm' : type;
    if (Utils.isEmpty(timestamp)) {
      return '';
    }

    if (typeof timestamp === 'string') {
      return dayjs(timestamp).format(formatType);
    }

    return dayjs.unix(timestamp as number).format(formatType);
  }

  /**
   * 时间展示规则
   * 1分钟以内  刚刚
   * 当天内  HH:mmm
   * 超出一天 MM-DD HH:mm
   * 跨年  YYYY-MM-DD HH:mm
   */
  static showTime(date) {
    const current = dayjs();
    const time = dayjs(date);
    const timeDiff = current.diff(time, 'minute');
    if (timeDiff <= 1) {
      return '刚刚';
    } if (timeDiff < 12 * 60) {
      return time.format('HH:mm');
    } if (timeDiff < 12 * 60 * 365) {
      return time.format('MM-DD HH:mm');
    }
    return time.format('YYYY-MM-DD HH:mm');
  }

  /* 将对象的健从下划线格式转为小驼峰格式
   * @param data 原始数据
   * @param keys 指定需要转的健，默认全部
   * @returns
   */
  static toCamelCaseObj<T, R, K extends keyof T>(data: T, keys: K[] = []): R {
    const result = {};
    if (data) {
      Object.keys(data).forEach((key) => {
        if (!keys.length || keys.includes(key as K)) {
          result[camelCase(key)] = data[key];
        }
      });
    }
    return result as R;
  }

  /* 用户名称显示规则 */
  static showUserName = (author: AuthorInfo = {}): string => {
    const { aliasName, name } = author ?? {};

    if (aliasName && name) {
      return `${aliasName}(${name})`;
    }

    return  name || aliasName || '';
  };

  /**
   * base64 转 blob
   * @param data base64
   * @param contentType 文件类型
   */
  static base64ToBlob = (data: string, contentType: string) => {
    let dataUrl = data;
    let mime = contentType;

    // blob 转成与 base64 对应 MIME type
    if (/^data:/.test(data)) {
      const [mimeString, base64String] = data.split(',');
      const [, type] = mimeString.match(/:(.*);/) as RegExpExecArray;
      dataUrl = base64String;
      mime = type;
    }

    const byteCharacters = atob(dataUrl);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mime });
  };

  /**
   * 下载 base64
   * @param base64String base64 不包含 MIME
   * @param mimeType 文件MIME 类型
   * @param fileName 文件名，不传的话默认值为随机数
   * ps. 如果文件名来自外部时需要完整附带后缀，文件名如果有带 ‘.’ mimeType 并不会让浏览器自动为文件添加后缀名
   */
  static downloadFileWithBase64 = ({
    base64String,
    mimeType,
    fileName,
  }: {
    base64String: string;
    mimeType: string;
    fileName?: string;
  }) => {
    const name = fileName || Utils.getRandomValues();
    const blob = Utils.base64ToBlob(base64String, mimeType);
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  /** 判断是否一个网址 */
  static isURL = (href: string) => href.match(/((https|http|ftp)?:\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|])/gi);

  /* 生成随机字符串 */
  static getRandomValues = () => {
    if (window.crypto) {
      const typedArray = new Uint32Array(2);
      window.crypto.getRandomValues(typedArray);
      const randomArr: string[] = [];
      typedArray.map((i: number) => randomArr.push(i.toString(36)));
      return randomArr.join('');
    }
    return Math.random().toString(36)
      .slice(2);
  };

  /** 判断是否在iframe中 */
  static isInFrame() {
    return window.parent !== window;
  }

  static getPrefixCls = (suffixCls: string) => `res-${suffixCls}`;

  /** 图片转换为二进制格式 */
  static dataURItoBlob(dataURI: string | undefined): Blob {
    const byteString = atob(dataURI!.split(',')[1]);
    const [mimeString] = dataURI!.split(',')[0].split(':')[1].split(';');
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  /**
   * 获取页面的标题（保持分隔符一致）
   * @param labels
   * @param suffix
   */
  static getDocumentTitle(labels: Array<string | undefined> | string = [], suffix = '腾讯调研云') {
    // 分割符
    const separator = ' - ';

    // 过滤空值
    const titles = (Array.isArray(labels) ? labels : [labels]).filter(label => Boolean(label));

    if (titles.length) {
      // 添加 suffix
      if (suffix) {
        titles.push(suffix);
      }

      return titles.join(separator);
    }

    return ['腾讯调研云', '专业的研究分析平台'].join(separator);
  }
}
