import Url from 'commons/utils/url';
import { isEmpty } from 'lodash-es';
import { ZoneType } from 'commons/types/zone';
import { isArticleEntity, MicroEntityType } from 'commons/types/micro';
import { historyBackUrlStorage } from 'commons/storage';
import { stringify } from 'qs';

/**
 * 链接参数：tab
 */
export interface URLSearchParamsTab {
  tab: string;
}

/**
 * 链接参数：分页
 */
export interface URLSearchParamsPagination {
  page: string;
  pageSize: string;
}

/** 路由的 path */
export const RoutePaths = {
  home: '/',

  /** 文章编辑器：编辑报告 */
  articleEditor: '/article/editor/:articleId',
  /** 文章编辑器：创建报告 */
  articleCreator: '/article/editor',
  /** 文章详情 */
  article: '/article',
  /** 文章详情（旧版链接，先保留，避免已经发出的报告链接无法正常访问） */
  oldArticle: '/article/:articleId',

  /** 报告/文章 编辑器提交结果提示页 */
  editorResult: '/:type/editor/result/:hashId',

  /** 报告上传页：编辑报告 */
  researchReportEditor: '/report/editor/:reportId',
  /** 报告上传页：创建报告 */
  researchReportCreator: '/report/editor',
  /** 报告详情页 */
  researchReport: '/report',
  /** 报告详情页（旧版链接，先保留，避免已经发出的报告链接无法正常访问） */
  oldResearchReport: '/report/:reportId',

  /** 报告生成器：编辑报告 */
  reportEditor: '/reporter/:reportId',
  /** 报告生成器：创建报告 */
  report: '/reporter/',

  /** 团队：团队邀请 */
  teamInvite: '/team/invite',
  /** 团队：团队管理 */
  teamManage: '/team/:teamId/manage',

  /** 个人中心 */
  mine: '/mine',
  /** 个人中心：我的账号 */
  mineAccount: '/mine/account',
  /** 个人中心：浏览记录 */
  mineHistory: '/mine/history',
  /** 个人中心：我的关注 */
  mineFollowing: '/mine/following',
  /** 个人中心：我的收藏 */
  mineCollection: '/mine/collection',

  /** 工作台 */
  workspace: '/workspace',
  /** 工作台-收藏 */
  workspaceCollection: '/workspace/collection',
  /** 工作台-个人工作台 */
  workspacePersonal: '/workspace/personal',
  /** 工作台-团队工作台 */
  workspaceTeam: '/workspace/team',

  /** 认证 */
  certification: '/certification',
  /** 个人认证 */
  personalCertification: '/certification/personal',
  /** 团队认证 */
  teamCertification: '/certification/team',

  /** 登录 */
  login: '/login',

  /** 关于我们 */
  about: '/about',

  /** 查看留资 */
  userInfos: '/userinfos',
  /** 研究工具介绍页 */
  toolIntroduction: '/tool/introduction',

  /** 帖子详情 */
  post: '/post',

  /** 电脑端复制页 */
  copy: '/copy',

  /** 数据统计 */
  dashboard: '/dashboard',
};

/**
 * 补充移动端前缀
 * @param path
 * @returns
 */
export const prefixMobile = (path: string) => `/mobile${path}`;

export class RouteTab {
  /**
   * 获取当前的 tab
   * @returns
   */
  static getTab() {
    const { tab } = Url.parseSearch<URLSearchParamsTab>();
    return tab;
  }

  /**
   * 指定 tab 是否已经选中
   * @param url
   * @returns
   */
  static isActive = (url = '') => {
    // 链接中的 tab
    const currentTab = this.getTab();

    // 指定需要判断的 tab
    const { tab } = Url.parseSearch<URLSearchParamsTab>(url);

    // 如果链接中有 tab 参数，直接判断，如果没有判断是否 ‘发现-推荐’模块
    return Boolean(currentTab) ? tab === currentTab : window.location.pathname === url;
  };

  /**
   * 链接中是否有 tab 参数
   */
  static hasTab(url = window.location.href) {
    const { tab } = Url.parseSearch<URLSearchParamsTab>(url);

    return !isEmpty(tab);
  }
}

/** ==================================== 发现 ==================================== */
export enum DiscoverRouteTab {
  /** 发现 */
  discovery = 'discovery',
  /** 发现 - 推荐 */
  recommend = '',
  /** 发现 - 推荐(移动端) */
  recommendM = 'recommend',
  /** 发现 - 报告 */
  report = 'report',
  /** 发现 - 文章 */
  article = 'article',
  /** 发现 - 研究交流 */
  post = 'post',
  /** 发现 - 工具 */
  tool = 'tool',
}

export const DiscoverRouteTabName = {
  [DiscoverRouteTab.recommendM]: '推荐',
  [DiscoverRouteTab.report]: '报告',
  [DiscoverRouteTab.article]: '文章',
  [DiscoverRouteTab.tool]: '工具',
  [DiscoverRouteTab.discovery]: '发现',
  [DiscoverRouteTab.post]: '想法',
};

export const DiscoverRouteFullTabName = {
  [DiscoverRouteTab.report]: '研究报告',
  [DiscoverRouteTab.article]: '研究文章',
  [DiscoverRouteTab.post]: '研究交流',
  [DiscoverRouteTab.tool]: '研究工具',
};
export class DiscoverRoute {
  /** 发现 */
  static root = '/discovery';

  /**
   * 获取指定tab的链接
   * @param tab
   * @param searchParams 需要额外添加的参数
   * @returns
   */
  static path(tab = DiscoverRouteTab.recommend, searchParams: Record<string, Raw> = {}) {
    const qs: string[] = [];

    if (tab) {
      qs.push(`tab=${tab}`);
    }

    Object.keys(searchParams).forEach((key) => {
      qs.push(`${key}=${searchParams[key]}`);
    });

    return `${DiscoverRoute.root}${qs.length ? `?${qs.join('&')}` : ''}`;
  }

  /**
   * 全部tab对应的链接
   * @returns
   */
  static paths() {
    const urls: Record<string, string> = {};

    Object.keys(DiscoverRouteTab).forEach((key) => {
      urls[DiscoverRouteTab[key]] = DiscoverRoute.path(DiscoverRouteTab[key]);
    });

    return urls as Record<DiscoverRouteTab, string>;
  }
}

/** ==================================== 消息中心 ==================================== */
export enum MessageRouteTab {
  comment = 'comment',
  like = 'praise_collect',
  nps = 'nps',
  system = 'system',
}

export const MessageRouteTabName = {
  [MessageRouteTab.comment]: '回复我的',
  [MessageRouteTab.like]: '赞和收藏',
  [MessageRouteTab.nps]: '报告评分',
  [MessageRouteTab.system]: '系统消息',
};

export class MessageRoute {
  /** 发现 */
  static root = '/message';

  /**
   * 指定tab的链接
   * @param tab
   * @returns
   */
  static path(tab = MessageRouteTab.comment) {
    return `${MessageRoute.root}?tab=${tab}`;
  }

  /**
   * 全部tab对应的链接
   * @returns
   */
  static paths() {
    const urls: Record<string, string> = {};

    Object.keys(MessageRouteTab).forEach((key) => {
      urls[MessageRouteTab[key]] = MessageRoute.path(MessageRouteTab[key]);
    });

    return urls as Record<MessageRouteTab, string>;
  }
}

/** ==================================== 搜索结果 ==================================== */
export enum SearchRouteTab {
  /** 全部 */
  all = 'all',
  /** 报告 */
  report = 'report',
  /** 文章 */
  article = 'article',
  /** 空间 */
  zone = 'zone',
}

export const SearchRouteTabName = {
  [SearchRouteTab.all]: '全部',
  [SearchRouteTab.report]: '报告',
  [SearchRouteTab.article]: '文章',
  [SearchRouteTab.zone]: '空间',
};

export const SearchRouteFullTabName = {
  [SearchRouteTab.report]: '研究报告',
  [SearchRouteTab.article]: '研究文章',
  [SearchRouteTab.zone]: '研究空间',
};
export class SearchRoute {
  /** 搜索结果 */
  static root = '/search';

  /**
   * 指定 tab 的链接
   */
  static path(tab = SearchRouteTab.all, keyword = '') {
    const qs = [`tab=${tab}`];

    if (keyword) {
      qs.push(`keyword=${keyword}`);
    }

    // 报告页默认列表显示
    if (tab === SearchRouteTab.report) {
      qs.push('viewType=list');
    }

    return `${SearchRoute.root}?${qs.join('&')}`;
  }

  /**
   * 全部 tab 的链接
   * @returns
   */
  static paths(keyword: string) {
    const urls: Record<string, string> = {};

    Object.keys(SearchRouteTab).forEach((key) => {
      urls[SearchRouteTab[key]] = SearchRoute.path(SearchRouteTab[key], keyword);
    });

    return urls as Record<SearchRouteTab, string>;
  }
}

/** ==================================== 空间 ==================================== */
export enum ZoneRouteTab {
  /** 报告 */
  report = 'report',
  /** 文章 */
  article = 'article',
  /** 关注者 */
  followers = 'followers',
  /** 资料（移动端） */
  info = 'info',
  /** 想法 */
  post = 'post',
}

export const ZoneRouteTabName = {
  [ZoneRouteTab.report]: '报告',
  [ZoneRouteTab.article]: '文章',
  [ZoneRouteTab.followers]: '关注者',
  [ZoneRouteTab.post]: '想法',
  [ZoneRouteTab.info]: '资料',
};

export class ZoneRoute {
  /** 空间：空间列表 */
  static zone = '/zone';
  /** 空间：个人空间 */
  static zoneUser = '/zone/user';
  /** 空间：团队空间 */
  static zoneTeam = '/zone/team';

  /**
   * 指定 tab 的链接
   * @param zoneId
   * @param zoneType
   * @param tab
   * @returns
   */
  static path(zoneId: string | number = '', zoneType = ZoneType.user, tab: ZoneRouteTab = ZoneRouteTab.report) {
    if (zoneType === ZoneType.team) {
      return `${ZoneRoute.zoneTeam}?tab=${tab}&teamId=${zoneId}`;
    }

    return `${ZoneRoute.zoneUser}?tab=${tab}&guid=${zoneId}`;
  }

  /**
   * 全部 tab 的链接
   * @param zoneId
   * @param zoneType
   * @returns
   */
  static paths(zoneId: string, zoneType = ZoneType.user) {
    const urls: Record<string, string> = {};

    Object.keys(ZoneRouteTab).forEach((key) => {
      urls[ZoneRouteTab[key]] = ZoneRoute.path(zoneId, zoneType, ZoneRouteTab[key]);
    });

    return urls as Record<ZoneRouteTab, string>;
  }

  /**
   * 从链接参数中获取空间 Id
   */
  static zoneId() {
    const search = Url.parseSearch<{ teamId?: string; guid?: string }>();

    if (search.teamId) {
      return String(search.teamId);
    }

    return search.guid || '';
  }
}

/** ==================================== 数据统计 ==================================== */

export enum DashboardContentsTab {
  /** 报告 */
  report = 'report',
  /** 文章 */
  article = 'article',
  /** 想法 */
  post = 'post',
}

export const DashboardContentsTabName = {
  [DashboardContentsTab.report]: '报告',
  [DashboardContentsTab.article]: '文章',
  [DashboardContentsTab.post]: '想法',
};

export class DashboardRoute {
  /** 数据统计 - 总览 */
  static overview = '/dashboard/overview';
  /** 数据统计 - 内容统计 */
  static contents = '/dashboard/contents';
  /** 数据统计 - 关注者统计 */
  static followers = '/dashboard/followers';

  /**
   * 指定 tab 的链接
   * @returns
   */
  static path({
    route,
    zoneId,
    zoneType = ZoneType.user,
    tab,
  }: {
    route: DashboardRoute;
    zoneId: string | number;
    zoneType?: ZoneType;
    tab?: DashboardContentsTab;
  }) {
    const query = stringify({
      [zoneType === ZoneType.team ? 'teamId' : 'guid']: zoneId,
      tab,
    });
    return `${route}?${query}`;
  }
}

/** ================================ 详情页 ================================ */

/** 访问详情页时自动展示指定弹窗或元素 */
export enum URLSearchParamsEntityViewAutoOpen {
  /**
   * 留资表单填写弹窗
   * - 当用户直接访问 pdf 阅读页面的链接时，如果还没有填写用户信息，跳转到报告概览页面并自动打开留资表单填写弹窗
   */
  USER_INFO_COLLECTION = 'user-info-collection',
}

/** 详情页链接中可能包含的参数 */
export interface URLSearchParamsEntityView {
  /** 实体 id */
  id: Raw;
  /**
   * 打开页面后滚动到指定位置
   * - comment 评论
   */
  scrollTo?: 'comment';
  /**
   * 打开页面后展示指定的弹窗
   * - user-info-collection 用户信息收集
   */
  autoOpen?: URLSearchParamsEntityViewAutoOpen;
  /**
   * - home 用于在移动端判断是否需要显示“返回首页”按钮
   */
  display?: 'home';
}

/** 编辑器链接中可能包含的参数 */
export interface URLSearchParamsEntityEditor {
  /** 实体 id */
  id?: Raw;
  /** 团队id */
  teamId?: string | number;
  /**
   * 滚动到指定区域
   * - setting 设置区域
   */
  scrollTo?: 'setting';
}

export class EntityRoute {
  /**
   * 从链接中获取参数
   * @returns
   */
  static searchParams() {
    const { searchParams } = Url.getRelativeUrl(window.location.search);

    const params: URLSearchParamsEntityView = {} as unknown as URLSearchParamsEntityView;

    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }

    return {
      ...params,
      id: params.id as string,
    };
  }

  /**
   * 获取详情页链接
   * @param searchParams
   */
  static path(searchParams: URLSearchParamsEntityView, entityType: MicroEntityType = MicroEntityType.ResearchReport) {
    switch (entityType) {
      case MicroEntityType.Article:
        return EntityRoute.pathArticle(searchParams);
      case MicroEntityType.ResearchReport:
        return EntityRoute.pathReport(searchParams);
      case MicroEntityType.Post:
        return EntityRoute.pathPost(searchParams);
      default:
        return EntityRoute.pathReporter(searchParams);
    }
  }

  /**
   * 获取 pdf 在线阅读链接
   * @param reportId 报告 id
   * @returns
   */
  static pathPDFViewer(reportId: string) {
    return `/pdf/web/viewer.html?r=${reportId}&a=/`;
  }

  /**
   * 报告详情页
   * @param searchParams
   * @returns
   */
  static pathReport(searchParams: URLSearchParamsEntityView) {
    return Url.addUrlParam(searchParams as any, RoutePaths.researchReport);
  }

  /**
   * 在线报告详情页
   * @param searchParams
   * @returns
   */
  static pathReporter(searchParams: URLSearchParamsEntityView) {
    return `${RoutePaths.report}${searchParams.id}`;
  }

  /**
   * 文章详情页
   * @param searchParams
   * @returns
   */
  static pathArticle(searchParams: URLSearchParamsEntityView) {
    return Url.addUrlParam(searchParams as any, RoutePaths.article);
  }

  /**
   * 帖子详情页
   * @param searchParams
   * @returns
   */
  static pathPost(searchParams: URLSearchParamsEntityView) {
    return Url.addUrlParam(searchParams as any, RoutePaths.post);
  }

  /**
   * 编辑器链接
   * @param searchParams
   * @param entityType
   * @returns
   */
  static pathEditor(searchParams: URLSearchParamsEntityEditor, entityType = MicroEntityType.ResearchReport) {
    const { id = '', teamId = '', ...rest } = searchParams;

    const params = { teamId, ...rest };

    switch (entityType) {
      case MicroEntityType.Article:
        return Url.addUrlParam(params, RoutePaths.articleEditor.replace(':articleId', String(id)));
      case MicroEntityType.Reporter:
        // 报告生成器记录当前页面地址，用于点击返回按钮时回到当前页面
        historyBackUrlStorage.set(window.location.href);

        return Url.addUrlParam(params, RoutePaths.reportEditor.replace(':reportId', String(id)));

      default:
        return Url.addUrlParam(params, RoutePaths.researchReportEditor.replace(':reportId', String(id)));
    }
  }

  /**
   * 编辑器结果页
   * @param searchParams
   * @param entityType
   * @returns
   */
  static pathEditorResult(searchParams: URLSearchParamsEntityEditor, entityType = MicroEntityType.ResearchReport) {
    const path = RoutePaths.editorResult.replace(':type', isArticleEntity(entityType) ? 'article' : 'report');

    const { id = '', ...params } = searchParams;

    return Url.addUrlParam(params, path.replace(':hashId', String(id)));
  }

  /**
   * 编辑页链接中获取参数
   * @returns
   */
  static searchParamsEditor() {
    const { teamId, ...rest } = Url.parseSearch<URLSearchParamsEntityEditor & { teamId: string }>();

    return {
      ...rest,
      teamId: /^\d+$/.test(teamId) ? parseInt(teamId, 10) : 0,
    };
  }

  /**
   * 数据统计页
   * @param searchParams
   * @returns
   */
  static pathDashboard(searchParams: URLSearchParamsEntityView) {
    return Url.addUrlParam(searchParams as any, RoutePaths.dashboard);
  }
}

interface URLSearchParamsUserInfos {
  /** 报告id */
  id: string;
}

/** 留资用户信息 */
export class UserInfoRoute {
  /**
   * 从链接中获取参数
   * @returns
   */
  static searchParams() {
    const { searchParams } = Url.getRelativeUrl(window.location.search);

    const params: URLSearchParamsUserInfos = {} as unknown as URLSearchParamsUserInfos;

    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }

    return params;
  }

  /**
   * 获取详情页链接
   * @param searchParams
   */
  static path(searchParams: URLSearchParamsUserInfos) {
    return Url.addUrlParam(searchParams as any, RoutePaths.userInfos);
  }
}
