import React, { lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import PrivateRoute from 'components/route/private-route';
import { DiscoverRoute, MessageRoute, RoutePaths, SearchRoute, ZoneRoute } from 'commons/route';
import {
  HistoryArticle,
  HistoryDiscover,
  HistoryMessage,
  HistoryPaths,
  HistoryReport,
  HistorySearch,
  HistoryZone,
} from 'components/route/history-routes';

import '@tencent/coral-style';

const HomeRecommend = lazy(() => import('pages/home-recommend'));
const Home = lazy(() => import('pages/home'));
const Reporter = lazy(() => import('pages/reporter'));
const RDBRoute = lazy(() => import('pages/rdb'));
const Mine = lazy(() => import('pages/mine'));
const ArticleEditor = lazy(() => import('pages/article-editor'));
const ArticleView = lazy(() => import('pages/article-view'));
const ReportEditor = lazy(() => import('pages/report-editor'));
const EditorResult = lazy(() => import('pages/editor-result'));
const ReportView = lazy(() => import('pages/report-view'));
const Discover = lazy(() => import('pages/discover'));
const WorkSpace = lazy(() => import('pages/work-space'));
const UserZone = lazy(() => import('pages/zone/user'));
const RecommendZone = lazy(() => import('pages/zone/recommend'));
const MessageCenter = lazy(() => import('pages/message-center'));
const TeamInvite = lazy(() => import('pages/team-invite'));
const TeamManage = lazy(() => import('pages/team-manage'));
const TeamZone = lazy(() => import('pages/zone/team-zone'));
const Login = lazy(() => import('pages/login'));
const SearchResult = lazy(() => import('pages/search-result'));
const Certification = lazy(() => import('pages/certification'));
const PersonalCertificate = lazy(() => import('pages/certification/personal-certificate'));
const TeamCertificate = lazy(() => import('pages/certification/team-certificate'));
const ResearchToolIntroduction = lazy(() => import('pages/home/research-tool-introduction'));
const UserInfos = lazy(() => import('pages/user-infos'));
const PostView = lazy(() => import('pages/post-view'));
const Dashboard = lazy(() => import('pages/dashboard'));

function App(): React.ReactElement {
  return (
    <Switch>
      {/* rdb系统 */}
      <Route path='/rdb' component={RDBRoute} />

      {/* 报告、文章表单结果提示页 */}
      <PrivateRoute path={RoutePaths.editorResult} component={EditorResult} />

      {/* 文章编辑 */}
      <PrivateRoute path={RoutePaths.articleEditor} component={ArticleEditor} />
      {/* 文章创建 */}
      <PrivateRoute exact path={RoutePaths.articleCreator} component={ArticleEditor} />
      {/* 文章详情 */}
      <Route exact path={RoutePaths.article} component={ArticleView} />

      {/* 工作台 */}
      <PrivateRoute path={RoutePaths.workspace} component={WorkSpace} />
      {/* 个人中心 */}
      <PrivateRoute path={RoutePaths.mine} component={Mine} />

      {/* 加入团队 */}
      <Route path={RoutePaths.teamInvite} component={TeamInvite} />
      {/* 团队管理 */}
      <Route path={RoutePaths.teamManage} component={TeamManage} />

      {/* 报告上传页：编辑报告 */}
      <PrivateRoute path={RoutePaths.researchReportEditor} component={ReportEditor} />
      {/* 报告上传页：创建报告 */}
      <PrivateRoute exact path={RoutePaths.researchReportCreator} component={ReportEditor} />
      {/* 报告详情 */}
      <Route exact path={RoutePaths.researchReport} component={ReportView} />

      {/* 报告生成器：编辑报告 */}
      <PrivateRoute path={RoutePaths.reportEditor} component={Reporter} />
      {/* 报告生成器：创建报告 */}
      <PrivateRoute path={RoutePaths.report} component={Reporter} />

      {/* 帖子详情页 */}
      <Route exact path={RoutePaths.post} component={PostView} />

      {/* 数据统计 */}
      <PrivateRoute path={RoutePaths.dashboard} component={Dashboard} />

      {/* =========== 处理历史路由:先保留,避免存量的链接无法访问 =========== */}
      {/* 文章详情页 */}
      <Route path={HistoryPaths.article} component={HistoryArticle} />
      {/* 报告详情页 */}
      <Route exact path={HistoryPaths.researchReport} component={HistoryReport} />
      {/* 空间 */}
      <Route path={HistoryPaths.zoneUser} component={HistoryZone} />
      <Route path={HistoryPaths.zoneTeam} component={HistoryZone} />
      {/* 搜索 */}
      <Route path={HistoryPaths.search} component={HistorySearch} />
      {/* 发现 */}
      <Route path={HistoryPaths.discover} component={HistoryDiscover} />
      {/* 消息中心 */}
      <Route path={HistoryPaths.notification} component={HistoryMessage} />

      {/* 个人认证信息收集页 */}
      <PrivateRoute path={RoutePaths.personalCertification} component={PersonalCertificate} />
      {/* 个人认证信息收集页 */}
      <PrivateRoute path={RoutePaths.teamCertification} component={TeamCertificate} />
      {/* 认证首页 */}
      <Route path={RoutePaths.certification} component={Certification} />

      {/* 个人空间 */}
      <Route path={ZoneRoute.zoneUser} component={UserZone} />
      {/* 团队空间 */}
      <Route path={ZoneRoute.zoneTeam} component={TeamZone} />
      {/* 空间列表 */}
      <Route exact path={ZoneRoute.zone} component={RecommendZone} />

      {/* 搜索结果 */}
      <Route path={SearchRoute.root} component={SearchResult} />

      {/* 消息中心 */}
      <PrivateRoute path={MessageRoute.root} component={MessageCenter} />

      {/* 研究工具介绍页 */}
      <Route path={RoutePaths.toolIntroduction} component={ResearchToolIntroduction} />

      {/* 关于我们 */}
      <Route path={RoutePaths.about} component={Home} />

      {/* 登录 */}
      <Route path={RoutePaths.login} component={Login} />

      {/* 查看报告留资 */}
      <Route path={RoutePaths.userInfos} component={UserInfos} />

      {/* 发现 */}
      <Route path={DiscoverRoute.root} component={Discover} />

      {/* 首页 */}
      <Route path={RoutePaths.home} component={HomeRecommend} />

      <Redirect to={RoutePaths.home} />
    </Switch>
  );
}

export default App;
