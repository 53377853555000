import qs from 'qs';
import { TdUploadFile } from 'tdesign-mobile-react';
import { ResponseCode } from 'commons/types/api';
import { ApplicationConfigKey, ApplicationConfigResult } from 'commons/types/application-config';
import { MicroApiFn } from 'commons/types/micro';
import Utils from 'utils';
import { LoginUrl } from './url';
import { COOKIE_HORIZON_SID, COOKIE_HORIZON_UID } from 'components/cookie';

interface RequestConfig extends RequestInit {
  data?: any;
  rtx?: string;
  mock?: boolean; // 是否mock数据
  apiUrl?: string; // 自定义apiUrl
  loginPath?: string; // 接口403跳转地址
  needLogin?: boolean; // 接口是否需要登录后才能访问，默认为true，返回的状态码为403时重定向到登录页面
}

export default function request<T>(url: string, {
  data, needLogin = true, ...restConfig
}: RequestConfig = {}): Promise<T> {
  // 需要mock数据则使用mock地址
  const apiUrl = restConfig.mock ? process.env.REACT_APP_API_URL_MOCK : restConfig.apiUrl || '/api';
  let u = url;
  const config: RequestConfig = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'user-guid': window.__USER_GUID__,
      'user-horizon-sid': COOKIE_HORIZON_SID.get() || '',
      'user-horizon-uid': COOKIE_HORIZON_UID.get() || '',
    },
    credentials: 'same-origin',
    mode: 'cors',
    ...restConfig,
  };
  const method = config.method?.toLocaleUpperCase();

  if (method === 'GET') {
    const queryParams = qs.stringify(data, { arrayFormat: 'repeat' });
    if (queryParams) {
      u += `?${queryParams}`;
    }
  } else {
    config.body = JSON.stringify(data ?? {});
  }

  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}${u}`, config)
      .then(response => response.json())
      .then((data) => {
        // 处理本地mock的数据
        const result = restConfig.mock && Utils.isEmpty(data.code) ? { code: 0, ...data } : data;
        if (result.code === ResponseCode.Forbidden && needLogin) {
          window.location.replace(restConfig.loginPath ?? LoginUrl.getLoginUrl());
          return Promise.reject({ message: '请重新登录' });
        }
        if (result.code === 0) {
          resolve(result);
        } else {
          reject(result);
        }
      }, reject);
  });
}

/**
 * 根据配置的 key 获取公开配置内容（因为接口返回的都是 JSON.stringify 后的数据，统一做一层数据处理）
 * 接口文档： http://cdc-micro.woa.com/openapi/docs#tag/Application/operation/Application_GetPublicConfig
 * 说明文档： https://iwiki.woa.com/pages/viewpage.action?pageId=4007601919
 * 数据配置地址：
 *  - 测试环境 https://cdc-micro.woa.com/admin/?from_wecom=1#/research/frontendconfig
 *  - 生产环境 https://admin-ur.woa.com/admin/#/research/frontendconfig
 * @param key 配置的 key
 * @returns
 */
export function requestPublicConfig<T>(key: ApplicationConfigKey): Promise<T> {
  return new Promise((resolve, reject) => {
    request<ApplicationConfigResult>(`/application/${key}`)
      .then((res) => {
        try {
          const data = JSON.parse(res.jsonConfig);
          resolve(data);
        } catch (error) {
          reject({ message: error });
        }
      })
      .catch(e => reject(e));
  });
}

/**
 * 请求问卷相关的接口时需要在服务端做多一层代理。接口文档地址：https://cdc-micro.woa.com/openapi/docs#tag/HttpProxy
 * @param url 需要请求的实际的问卷接口
 */
export function requestWJ<T>(url: string, { data, ...restConfig }: RequestConfig = {}): Promise<T> {
  return request('/httpproxy/wj', {
    data: {
      // 请求问卷接口的参数
      params: {
        ...data,
        _: Date.now(), // 问卷接口加上时间戳
      },
      // 请求接口地址
      uri: `/api${url}`,
      // 请求方法
      method: restConfig.method || 'GET',
    },
    method: 'POST',
    ...restConfig,
  });
}

/**
 * 封装微服务接口请求。微服务接口请求将状态为 200 返回结果统一处理为成功状态，在业务中再根据返回结果中的code值判断是否请求成功
 * @param queryFn 具体的请求函数
 * @returns
 */
export function requestMicroApi<TData extends { code?: number }>(queryFn: MicroApiFn<TData>): Promise<TData> {
  return new Promise((resolve, reject) => {
    queryFn()
      .then((data) => {
        if (data?.data?.code === ResponseCode.Success) {
          resolve(data.data);
        } else {
          reject(data.data);
        }
      }, reject);
  });
}

/**
  * 统一上传图片接口请求
  * 上传接口文档：https://cdc-micro.woa.com/openapi/docs#tag/Upload/operation/Upload_UploadImage
  * @param file
  * @param appName
  * @returns
 */
export function uploadImage(file: Blob | File, appName = 'ucloud'): Promise<string> {
  const formData = new FormData();
  formData.append('attachment', file);
  formData.append('appName', appName);

  const config: RequestConfig = {
    method: 'POST',
    body: formData,
  };

  return new Promise((resolve, reject) => {
    fetch(`/api/upload/${appName}/image`, config)
      .then(response => response.json())
      .then((data) => {
        if (data.code === 0) {
          resolve(data.filepath);
        } else {
          reject(data);
        }
      }, reject);
  });
}

/** 将文件转为base64格式
 * @param file
 * @returns
 */
export function toBase64(file: File): Promise<string | null | ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const { result } = reader;
      resolve(typeof result === 'string' ? result.replace(/^data:(\S*)base64,/, '') : result);
    };
    reader.onerror = e => reject(e);
    reader.readAsDataURL(file);
  });
}

export function jsonp<T>(params: {
  url: string;
  callbackName: string;
  data: any;
  timeout?: number;
}): Promise<T> {
  return new Promise((resolve, reject) => {
    let timmerId: NodeJS.Timeout | null = null;

    const queryParams = qs.stringify({
      ...(params.data || {}),
      callback: params.callbackName,
      v: Date.now(), // 设置一个随机数防止缓存
    });

    const head = document.getElementsByTagName('head')[0];
    // 创建 script 标签
    const script = document.createElement('script');
    // 通过 script 标签发送请求
    script.src = `${params.url}?${queryParams}`;

    window[params.callbackName] = function (res) {
      if (timmerId) {
        clearTimeout(timmerId);
      }
      head.removeChild(script);
      window[params.callbackName] = null;

      resolve(res);
    };

    head.appendChild(script);
    // 设置请求超时
    if (params.timeout) {
      timmerId = setTimeout(() => {
        head.removeChild(script);
        window[params.callbackName] = null;
        reject({ msg: '请求超时' });
      }, params.timeout);
    }
  });
}

// 下载认证相关图片
export function downloadImage(uuids?: (string | undefined)[]): Promise<TdUploadFile[]> {
  if (!uuids?.[0]) return ([] as any);
  const apis = uuids?.map(uuid => new Promise<TdUploadFile>((resolve, reject) => {
    fetch(`/api/certification/files/${uuid}/download`, { method: 'GET' })
      .then(res => res.json())
      .then((data) => {
        if (data.code === 0) {
          resolve({ url: `data:image/png;base64,${data.data}`, name: data.filename, status: 'success' } as any);
        } else {
          reject(data);
        }
      }, reject);
  }));
  return  Promise.all([...apis]);
}
