import React, { ReactNode, useState, useContext, useRef } from 'react';
import { CertificationStatus } from 'commons/types/certification';
import { LoginOptions, LoginStatus, UserInfo } from 'commons/types/user';
import { infoRequiredStorage } from 'components/storage';
import { useInfoSettingDialog } from 'components/user-info-setting';
import { RoutePaths } from 'commons/route';
import { useLogin } from 'context/login-context';

/**
 * 获取用户信息结果
 */
export type LoginResult = {
  /** 接口执行状态 */
  status: LoginStatus;
  /** 接口返回结果 */
  user: UserInfo;
};

interface AuthContextValue {
  /** 登录接口执行状态 */
  logining: boolean; // 正在登录
  logined: boolean; // 已登录
  loginStatus: LoginStatus;
  /** 当前登录用户信息 ref */
  userRef: React.MutableRefObject<UserInfo>;
  /** 当前登录用户信息 */
  user: UserInfo;
  /** 当前登录用户的认证状态 */
  certificationStatus: CertificationStatus;
  /** 是否开启公众号消息提醒 */
  weappEnable: boolean;
  /** 用户是否关注公众号 */
  isFollowOfficialAccounts: boolean;
  /** 是否加载完成是否关注公众号接口 */
  isLoadingFollowOfficialAccounts: boolean;
  setIsLoadingFollowOfficialAccounts: (isFetchingFocus: boolean) => void;
  /** 设置是否关注公众号 */
  setIsFollowOfficialAccounts: (isFollowOfficialAccounts: boolean) => void;
  /** 设置是否接收消息推送 */
  setWeAppEnable: (weappEnable: boolean) => void;
  /** 登录 */
  onLogin: (res: LoginResult) => void;
  /** 退出登录 */
  onLogout: () => void;
  /** 更新当前登录用户的头像等信息 */
  onUpdate: (userInfo: Pick<UserInfo, 'avatar' | 'name'>) => void;
  /** 设置当前登录用户认证状态 */
  setCertificationStatus: (status: CertificationStatus) => void;
  /** 判断登陆用户是否是报告或文章创建者，用于团队工作台区分不同权限 */
  isReportAutor: (reportAuthorId?: string) => boolean;
  /** 权限校验 */
  auth: (options: LoginOptions) => void;
}

const AuthContext = React.createContext<AuthContextValue | undefined>(undefined);
AuthContext.displayName = 'AuthContext';

export const AuthProvider = ({ children }: { children: ReactNode }): React.ReactElement => {
  const userRef = useRef<UserInfo>({});
  // 登录态
  const [loginStatus, setLoginStatus] = useState(LoginStatus.loading);
  const [certificationStatus, setCertificationStatus] = useState(CertificationStatus.uncertified);
  const [user, setUser] = useState<UserInfo>({});
  const [weappEnable, setWeAppEnable] = useState<boolean>(false);
  const [isFollowOfficialAccounts, setIsFollowOfficialAccounts] = useState<boolean>(false);
  const [isLoadingFollowOfficialAccounts, setIsLoadingFollowOfficialAccounts] = useState<boolean>(true);

  const { mutate: showInfoSetting } = useInfoSettingDialog();
  const { onShow: showLogin, callbackRef } = useLogin();

  /**
   * 获取用户信息接口请求成功
   * @param res
   */
  const onLogin = (res: LoginResult) => {
    setLoginStatus(res.status);
    setUser(res.user);
    userRef.current = res.user;

    if (res.status === LoginStatus.success) {
      showInfoSetting({
        user: res.user,
        updateInfo: onUpdate,
        callback: callbackRef.current,
      });
    }
  };

  /**
   * 退出登录
   */
  const onLogout = () => {
    setLoginStatus(LoginStatus.error);
    setUser({});
    userRef.current = {};

    window.location.replace(RoutePaths.home); // 退出登录后回到首页
  };

  /**
   * 验证是否登录，未登录直接登录
   * @param options 登录相关配置
   */
  const auth = (options: LoginOptions = {}) => {
    const { phoneRequired = true, infoRequired = true, callback } = options;

    // 记录当前场景是否需要设置头像和昵称
    if (infoRequired) {
      infoRequiredStorage.set(true);
    }

    switch (loginStatus) {
      case LoginStatus.success:
      case LoginStatus.needBindPhone:
        if (phoneRequired && loginStatus === LoginStatus.needBindPhone) {
          return;
        }

        // 提示设置头像和昵称
        showInfoSetting({
          user,
          callback,
          updateInfo: onUpdate,
        });
        return;
      default:
        // 未登录,显示登录弹窗
        showLogin(options);
    }
  };

  /**
   * 判断是否报告作者
   * @param reportAuthorId 当前报告的作者 id
   * @returns
   */
  const isReportAutor = reportAuthorId => reportAuthorId === user.guid;

  /**
   * 更新用户信息：在个人账号模块中更新用户昵称和头像
   * @param userInfo
   */
  const onUpdate = (userInfo: Pick<UserInfo, 'avatar' | 'name'>) => {
    const nextUser = { ...user, ...userInfo };
    setUser(nextUser);
    userRef.current = nextUser;
  };

  return (
    <AuthContext.Provider value={{
      user,
      userRef,
      loginStatus,
      logined: loginStatus === LoginStatus.success,
      logining: loginStatus === LoginStatus.loading,
      certificationStatus,
      weappEnable,
      isFollowOfficialAccounts,
      isLoadingFollowOfficialAccounts,
      setCertificationStatus,
      setIsFollowOfficialAccounts,
      setIsLoadingFollowOfficialAccounts,
      setWeAppEnable,
      onLogin,
      onLogout,
      onUpdate,
      isReportAutor,
      auth,
    }}>{children}</AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextValue => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth 必须在AuthProvier中使用');
  }
  return context;
};
