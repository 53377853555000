import { CertificationStatus } from 'commons/types/certification';

export interface UserInfo {
  /** 用户头像 */
  avatar?: string;
  /** 用户名称：一般用于显示 */
  name?: string;
  /** 用户id：唯一标识 */
  guid?: string;
  /** 用户类型 */
  userType?: string;
  /** 用户认证状态 */
  certificationStatus?: CertificationStatus;
  /** 个人简介 */
  introduction?: string;
  /** 类型（移动端） */
  type?: 'user' | 'team';
}

/** 作者信息 */
export interface AuthorInfo extends UserInfo {
  aliasName?: string;
}

/**
 * 第三方账号类型
 */
export enum UserAccountTypes {
  /** 手机号 */
  phone = 'PHONE',
  /** QQ */
  qq = 'QQ',
  /** 微信 */
  wechat = 'Wechat',
  /** OA */
  oa = 'OA',
  /** 小程序 */
  mp = 'MP'
}

/**
 * 账号绑定的第三方账号
 */
export interface UserAccount {
  /** 头像 */
  avatar: string;
  /** 绑定的账号类型 */
  kind: UserAccountTypes;
  /** 昵称 */
  name: string;
  /** 解绑对应账号时使用该值 */
  openid: string;
  /** 第三方平台的id */
  thirdPartyOpenid: string;
}

export declare namespace AccountAPI {
  // 修改用户头像和昵称
  type UpdateAccountInfo = {
    avatar?: string; // 头像
    name?: string; // 昵称
  };
}

/** 获取用户是否关注公众号接口返回结果 */
export interface LoadWechatIsSubscribedResult {
  /** 是否关注了公众号 */
  wechatIsSubscribed: boolean;
}

/**
 * 登录状态
 */
export enum LoginStatus {
  /** 正在获取用户登录状态 */
  loading,
  /** 已登录且已绑定手机号 */
  success,
  /** 已登录但未绑定手机号（部分使用场景不要求绑定手机号 eg：在线阅读） */
  needBindPhone,
  /** 未登录 */
  error
}

/**
 * 登录的一些配置
 */
export interface LoginOptions {
  /** 登录后需要执行的回调 */
  callback?: () => void;
  /** 当前场景是否要求绑定手机号 */
  phoneRequired?: boolean;
  /** 当前场景是否要求设置用户头像、昵称 */
  infoRequired?: boolean;
  /** 登录成功后重定向的地址(pc端) */
  redirectUrl?: string;
}
