import request from 'utils/request';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { MessageCenterModel, MessageCenterAPI } from 'commons/types/message-center';
import { useMessageCenter } from 'context/message-center-context';
import { useAuth } from 'context/auth-context';
import { MicroAppName } from 'commons/types/micro';

export const useLoadRead = () => {
  const queryClient = useQueryClient();
  return useMutation((params: MessageCenterAPI.ReadParams) => request('/notice/read/RESEARCH', {
    method: 'POST',
    data: {
      ...params,
    },
  }), {
    onSuccess: () => {
      queryClient.invalidateQueries('notice/RESEARCH');
      queryClient.invalidateQueries('notice/unread-count/RESEARCH');
    },
  });
};

export const useLoadUnReadCount = (params: MessageCenterAPI.UnReadCountParams) => {
  const { setValue } = useMessageCenter();
  const { logined } = useAuth();
  return useQuery<MessageCenterModel.UnReadCount>(
    ['notice/unread-count/RESEARCH'],
    () => request('/notice/unread-count/RESEARCH', {
      data: { 'filter.action_types': params.actionTypes, 'filter.entity_types': params.entityTypes },
    }),
    {
      keepPreviousData: true,
      enabled: logined,
      onSuccess: ({ countMap }) => {
        const { Comment, Collect, Like, Nps, SystemAudit, SystemUnpublish, ValidationCollect } = countMap;
        const commentCount = Comment?.entityCountMap?.total ?? 0;
        const collectLikeCount = Collect?.entityCountMap?.total  + Like?.entityCountMap?.total;
        const npsCount = Nps?.entityCountMap?.total ?? 0;
        // eslint-disable-next-line max-len
        const systemMessageCount = SystemAudit?.entityCountMap?.total + SystemUnpublish?.entityCountMap?.total + ValidationCollect?.entityCountMap?.total;
        const count = commentCount + collectLikeCount + npsCount + systemMessageCount;
        setValue({
          commentCount,
          collectLikeCount,
          npsCount,
          systemMessageCount,
          count,
        });
      },
    }
  );
};

type UseLoadNoticeOptions = UseQueryOptions<
MessageCenterModel.MessageCenterDetail,
API.ResultError,
MessageCenterModel.MessageCenterDetail,
string[]
>;
export const useLoadNotice = (params: MessageCenterAPI.NoticeParams, options?: UseLoadNoticeOptions) => {
  const { logined } = useAuth();
  return useQuery(
    ['notice/RESEARCH', ...Object.keys(params).map(key => params[key])],
    () => request<MessageCenterModel.MessageCenterDetail>('/notice/RESEARCH', {
      data: {
        'filter.action_types': params.actionTypes,
        'filter.entity_types': params.entityTypes,
        'pagination.page': params.page,
        'pagination.size': params.size,
      },
    }),
    {
      keepPreviousData: true,
      enabled: logined,
      ...options,
    },
  );
};

// 获取消息提醒开关
export const useLoadNoticeSwitch = () => {
  const { setWeAppEnable } = useAuth();
  return useMutation<{code: number; weappEnable: boolean}>(
    ['notice/remind-switch', MicroAppName.RESEARCH],
    () => request('/notice/remind-switch', {
      data: {
        app: MicroAppName.RESEARCH,
      },
    }),
    {
      onSuccess: (data) => {
        setWeAppEnable(data.weappEnable);
      },
    }
  );
};

// 设置消息提醒开关
export const useLoadSwitchNoticeRemind = () => {
  const queryClient = useQueryClient();
  return useMutation((weappEnable: boolean) => request('/notice/remind-switch', {
    method: 'POST',
    data: {
      weappEnable,
    },
  }), {
    onSuccess: () => {
      queryClient.invalidateQueries('/notice/remind-switch');
    },
  });
};
