import { escape } from 'lodash-es';

/**
 * 获取浏览器 Cookie
 */
export class GlobalCookie {
  /**
   * 存储的 key
   */
  key: string;

  constructor(key: string) {
    this.key = key;
  }

  /**
   * 设置 cookie
   * @param value
   * @param expire 过期时间毫秒
   */
  set(value: string, expire: number) {
    try {
      const v = JSON.stringify(value);
      let e = '';

      // 设置过期时间
      if (expire) {
        const d = new Date();

        d.setTime(d.getTime() + expire);

        e = `; expires=${d.toUTCString()}`;
      }

      document.cookie = `${this.key}=${escape(v)}${e}; path=/`;

      return true;
    } catch (error) {
      return false;
    }
  }

  get(): string | null {
    const items = document.cookie.split(';');

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < items.length; i += 1) {
      // 清除空格
      const [key, value] = items[i].split('=') ?? [];
      if (key?.trim() === this.key) {
        return value;
      }
    }

    return null;
  }
}
